import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddToCalendar from 'react-add-to-calendar';

import CTA from 'public/components/AttractionScreen/CTA';
import {
  ContentfulImage,
  ContentfulRichText,
  DesktopOnly,
  MobileOnly,
  SVGIcon,
} from 'common/components';
import { useClassName } from 'common/hooks';
import { useSnackbar } from 'public/components/Snackbar';

import './new-event-card.less';
import { getAddToCalendarEvent } from '../utils';

const ADD_TO_CALENDAR_CLASSES = ['calendar', 'link'];

const NewEventCard = ({ event, setSelected, selectedDate }) => {
  const [showShareSuccess, shareSnackbar] = useSnackbar();
  const classNames = useClassName('NewEventCard');

  const calendarEvent = getAddToCalendarEvent(event, selectedDate);

  const handleCardClick = (e) => {
    if (
      ADD_TO_CALENDAR_CLASSES.some((elClass) =>
        e.target.className.includes(elClass)
      )
    )
      return;
    setSelected();
  };

  const canShare = () => Boolean(navigator?.clipboard?.writeText);

  const share = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    navigator.clipboard
      .writeText(`${location.origin}/events/${event.slug}`)
      .then(function () {
        showShareSuccess(true);
      });
  };

  const renderActions = () => {
    return (
      <div className={classNames('actions')}>
        {canShare() && (
          <div onClick={share}>
            <SVGIcon name="share" />
          </div>
        )}
        {calendarEvent && (
          <div className={classNames('add-calendar-container')}>
            <AddToCalendar
              buttonTemplate={{ 'calendar-plus-o': 'left' }}
              rootClass={classNames('add-calendar')}
              buttonClassOpen={classNames('add-calendar-open')}
              buttonClassClosed={classNames('add-calendar-closed')}
              dropdownClass={classNames('add-calendar-dropdown')}
              buttonLabel={''}
              event={calendarEvent}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div onClick={handleCardClick} className={classNames('container')}>
      {shareSnackbar}
      <ContentfulImage
        field={event.images}
        width={382}
        className={classNames('image')}
      />
      <div className={classNames('content')}>
        <div className={classNames('title')}>
          <div className={classNames('info')}>
            <h4>{event.title}</h4>
            {event?.venue?.slug ? (
              <Link
                to={{ pathname: `/venue/${event?.venue?.slug}` }}
                smooth="true">
                {event?.venue?.name}
              </Link>
            ) : (
              <h6>{event?.locationDescription}</h6>
            )}
          </div>
          <DesktopOnly>{renderActions()}</DesktopOnly>
        </div>
        <ContentfulRichText
          field={event.shortDescription}
          className={classNames('details')}
        />
        {event.url && (
          <CTA
            text={event.type}
            link={event.url}
            className={classNames('cta')}
            onClick={(ev) => ev.stopPropagation()}
          />
        )}
        <MobileOnly>{renderActions()}</MobileOnly>
      </div>
    </div>
  );
};

NewEventCard.propTypes = {
  event: PropTypes.object,
  setSelected: PropTypes.func,
  selectedDate: PropTypes.object,
};

export default NewEventCard;
