import React from 'react';
import { Link } from 'react-router-dom';
import { useClassName } from 'common/hooks';
import PropTypes from 'prop-types';
import { LoaderImage } from 'public/components';
import { getContentfulField } from 'common/components';
import Breadcrumb from '../../../components/Breadcrumb';
import './layout.less';
import logo from 'common/assets/footer-logo.svg';

const Layout = ({ children, links, info, title, hero }) => {
  const className = useClassName('Container');

  const getContentfulImage = (contentfulImage) => {
    if (!contentfulImage) return null;

    let image = getContentfulField(contentfulImage);
    if (Array.isArray(image)) image = image[0];
    const file = getContentfulField(image.fields.file);
    const title = getContentfulField(image.fields.title);
    const src = file.url;
    return { title, src };
  };

  const renderHero = () => {
    const { heroImage, logoImage } = hero;

    if (!heroImage) return null;

    const heroContentfulImage = getContentfulImage(heroImage);
    const logoContentfulImage = getContentfulImage(logoImage);
    return (
      <div className={className('hero')}>
        <div className={className('hero-image')}>
          <LoaderImage
            src={heroContentfulImage.src}
            alt={heroContentfulImage.title}
            fluid
            hideLoader
          />
        </div>
        {logoImage && (
          <div className={className('logo-image')}>
            <LoaderImage
              src={logoContentfulImage.src}
              alt={logoContentfulImage.title}
              fluid
              hideLoader
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={className('main')}>
      <div className={className('content')}>
        {links?.length > 0 && <Breadcrumb links={links} />}
        {hero && renderHero()}
        {info && <p className={className('info')}>{info}</p>}
        {title && <p className={className('title')}>{title}</p>}
        {children}
      </div>
      <footer className={className('assistance-footer')}>
        <p className={className('assistance-message')}>
          If you are experiencing issues updating your booking online please
          contact Guest Services for assistance with this booking by visiting
          our <Link to={'/contact'}>Contact page</Link>
        </p>
        <img
          src={logo}
          alt="AMERICAN DREAM"
          className={className('footer-logo')}
        />
      </footer>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  info: PropTypes.string,
  title: PropTypes.string,
  hero: PropTypes.shape({
    heroImage: PropTypes.object.isRequired,
    logoImage: PropTypes.object,
  }),
};

export default Layout;
