import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useClassName } from 'common/hooks';
import { ContentfulRichText } from 'common/components';
import './deal.less';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Deal = ({ deal }) => {
  const className = useClassName('Deal');
  const discountType = deal.discountType || 'from';

  const getDates = () => {
    const startDate = DateTime.fromISO(deal.startDate);
    const endDate = DateTime.fromISO(deal.endDate);
    return `${startDate.toFormat('LLL dd')} - ${endDate.toFormat('LLL dd')}`;
  };

  return (
    <div className={className('container')}>
      <div className={className('header')}>
        <img src={deal.venueImage} alt={deal.venueName} />
      </div>
      <div className={className('body')}>
        <div className={className('overline')}>{deal.venueName}</div>
        <div className={className('title')}>{deal.title}</div>
        <div className={className('description')}>
          <ContentfulRichText field={deal.shortDescription} />
        </div>
        <div className={className('labels')}>
          {deal.discountLabel && (
            <div className={className(['label'])}>{deal.discountLabel}</div>
          )}
          {deal.discountPercentage && (
            <div className={className(['label', `label-${discountType}`])}>
              {`${discountType.replace(/-/g, ' ')} ${
                deal.discountPercentage
              }% off`}
            </div>
          )}
        </div>
      </div>
      <div className={className('footer')}>
        <div className={className('cta-container')}>
          <div className={className('info')}>
            <div className={className('dates')}>{getDates()}</div>
            <div className={className('location')}>{deal.location}</div>
          </div>
          <Link to={`/deals/${deal.slug}`} className={className('cta')}>
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

Deal.propTypes = {
  deal: PropTypes.object.isRequired,
};

export default Deal;
