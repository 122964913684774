import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { DesktopOnly, MobileOnly } from 'common/components';

import EventCard from './EventCard';
import EventListView from './EventListView';

const EventList = ({
  eventItems,
  setSelected,
  viewType,
  selectedDate,
  handleViewTypeChange,
}) => {
  const renderEvents = (columns) => {
    if (eventItems.every((a) => a.externalId)) {
      return (
        <EventListView
          events={eventItems}
          setSelected={setSelected}
          selectedDate={selectedDate}
          viewType={viewType}
          handleViewTypeChange={handleViewTypeChange}
        />
      );
    }

    return (
      <Grid columns={columns}>
        {eventItems.map((event) => (
          <EventCard event={event} key={event._id} />
        ))}
      </Grid>
    );
  };

  return (
    <>
      <DesktopOnly>{renderEvents(4)}</DesktopOnly>
      <MobileOnly>{renderEvents(1)}</MobileOnly>
    </>
  );
};

EventList.propTypes = {
  eventItems: PropTypes.array.isRequired,
  setSelected: PropTypes.func,
  viewType: PropTypes.string,
  selectedDate: PropTypes.object,
  handleViewTypeChange: PropTypes.func,
};

export default EventList;
