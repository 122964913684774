import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import { DateTime } from 'luxon';

import {
  ContentfulImage,
  ContentfulRichText,
  getContentfulAssetUrlandAlt,
} from 'common/components';
import { LoaderImage } from 'public/components/Loader';
import CTA from 'public/components/AttractionScreen/CTA';
import { EventDetailsBreadcrumb } from 'public/components';
import { useClassName } from 'common/hooks';
import { urlForUpload } from 'utils/api';
import { formatRegularHours } from 'utils/helpers/formatOpeningHours';
import calendarIcon from 'common/assets/deals-calendar.svg';
import clockIcon from 'common/assets/event-clock.svg';

import { VenueDetailsMap } from '../../components/VenueDetailsScreen/VenueDetailsMap';

import './ad-event-details.less';

const isPast = (schedule) => {
  const today = DateTime.fromObject({
    zone: 'America/New_York',
  }).startOf('day');

  return today > schedule.endDate;
};

const getSchedule = (event) => {
  const schedules = event.schedules.map((a) => ({
    ...a,
    startDate: DateTime.fromFormat(a.startDate, 'yyyy-MM-dd', {
      zone: 'America/New_York',
    }),
    endDate: DateTime.fromFormat(a.endDate, 'yyyy-MM-dd', {
      zone: 'America/New_York',
    }),
  }));

  return (
    schedules.find(
      (schedule, index) => !isPast(schedule) || index === schedule.length - 1
    ) || schedules[schedules.length - 1]
  );
};

const AdEventDetail = (props) => {
  const { slug } = props.match.params;
  const [event, setEvent] = useState(null);
  const className = useClassName('AdEventDetails');
  const { pathname } = useLocation();

  useEffect(() => {
    fetchEventBySlug();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const fetchEventBySlug = async () => {
    const data = await props.adEvents.getEventBySlug(slug);
    setEvent(data);
  };

  const renderDates = () => {
    if (event.externalId) {
      const schedule = getSchedule(event);

      return [
        schedule.startDate,
        ...(schedule.startDate.equals(schedule.endDate)
          ? []
          : [schedule.endDate]),
      ]
        .map((date) => date.toFormat('LLL dd'))
        .join(' - ');
    }

    const { startDate, endDate } = event;
    const tempStartDate = startDate.split('T')[0];
    const tempEndDate = endDate.split('T')[0];
    if (tempStartDate === tempEndDate) {
      return new Date(startDate).toLocaleDateString('en', {
        month: 'short',
        day: 'numeric',
      });
    }
    return `${new Date(startDate).toLocaleDateString('en', {
      month: 'short',
      day: 'numeric',
    })} - ${new Date(endDate).toLocaleDateString('en', {
      month: 'short',
      day: 'numeric',
    })}`;
  };

  const getHours = () => {
    if (event.externalId) {
      const schedule = getSchedule(event);
      const oneDayEvent = schedule.startDate.equals(schedule.endDate);
      const hours = formatRegularHours({ regular: schedule.weekdays }, true);
      return oneDayEvent && hours.length === 1
        ? [hours[0].split(':').splice(1).join(':').trim()]
        : hours;
    }

    return [
      [event.startTime, event.endTime]
        .map((time) => DateTime.fromFormat(time, 'H:m').toFormat('h:mm a'))
        .join(' - '),
    ];
  };

  const renderImage = () => {
    if (event.images) {
      return <ContentfulImage field={event.images} width={1000} />;
    }

    if (event.image) {
      return (
        <div
          style={{ backgroundImage: `url("${urlForUpload(event.image)}")` }}
        />
      );
    }

    if (event.venue?.content?.logo) {
      return (
        <div
          style={{
            backgroundImage: `url("${
              getContentfulAssetUrlandAlt(event.venue.content.logo).assetUrl
            }")`,
          }}
        />
      );
    }

    return (
      <div className={className('empty-event-img')}>
        {event.venue && event.venue.name}
        {!event.venue && event.locationDescription}
      </div>
    );
  };

  const renderTermsAndConditions = () => {
    if (!event.term && !event.termsAndConditions) return null;

    return (
      <div className={className('term-condition-wrapper')}>
        <span>Terms and conditions</span>
        {event.term ? (
          <p>{event.term}</p>
        ) : (
          <ContentfulRichText field={event.termsAndConditions} />
        )}
      </div>
    );
  };

  if (!event) return <></>;

  return (
    <Container className={className('details-page-wrapper')}>
      <EventDetailsBreadcrumb
        type={{ name: event.venue?.name || '', slug: event.venue?.slug || '' }}
      />
      <div className={className('details-wrapper')}>
        <div className={className('details-info')}>
          <div className={className('details-venue-name')}>
            {event.venue && event.venue.name}
            {!event.venue && event.locationDescription}
          </div>
          <div className={className('details-event-title')}>
            {event && event.title}
          </div>
          <div className={className('details-event-dates')}>
            <div className={className('details-event-dates-icon')}>
              <LoaderImage
                src={calendarIcon}
                alt={''}
                loadingHeight={20}
                hideLoader
                fluid
              />
            </div>
            {renderDates()}
          </div>
          <div className={className('details-event-dates')}>
            <div className={className('details-event-dates-icon')}>
              <LoaderImage
                src={clockIcon}
                alt={''}
                loadingHeight={20}
                hideLoader
                fluid
              />
            </div>
            <div className={className('details-event-hours')}>
              {getHours().map((hour) => (
                <span key={hour}>{hour}</span>
              ))}
            </div>
          </div>
          <div className={className('details-event-description')}>
            {event.details ? (
              <ContentfulRichText field={event.details} />
            ) : (
              event.description
            )}
          </div>
          {event && event.url && (
            <CTA
              text={event.type}
              link={event.url}
              className={className('cta')}
            />
          )}
        </div>
        <div className={className('details-img')}>{renderImage()}</div>
      </div>
      <div className={className('details-location-wrapper')}>
        {event.venue?.jibestreamDestinationId && (
          <VenueDetailsMap
            jibestreamDestinationId={event.venue.jibestreamDestinationId}
          />
        )}
      </div>
      {renderTermsAndConditions()}
    </Container>
  );
};

AdEventDetail.propTypes = {
  props: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export default inject('adEvents')(AdEventDetail);
