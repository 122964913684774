import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';

import './calendar-event.less';

const CalendarEvent = ({ eventInfo }) => {
  const className = useClassName('CalendarEvent');

  return (
    <div className={className('container')}>
      <div className={className('events-indicator')} />
      <div className={className('hours')}>
        {eventInfo?.event?.extendedProps?.hours}
      </div>
      <div className={className('title-container')}>
        {eventInfo?.event?.extendedProps?.isFeatured && (
          <div className={className('featured')} />
        )}
        <div className={className('title')}>{eventInfo?.event?.title}</div>
      </div>
    </div>
  );
};

CalendarEvent.propTypes = {
  eventInfo: PropTypes.object.isRequired,
};

export default CalendarEvent;
