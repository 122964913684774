import React from 'react';
import { inject, observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { DateTime } from 'luxon';
import { Component, getQuantity } from 'common/helpers';
import { AddOnCardNew } from 'public/components';
import ChangeContainer from './ChangeContainer';
import { getQuantities, getInventory } from '../helpers/orderUpdate';
import { SVGIcon as Icon } from 'common/components';

import './add-on-selector.less';

@inject('orderUpdate', 'ticketInventory')
@observer
export default class AddOnSelector extends Component {
  constructor(props) {
    super(props);
    const { currentTickets } = this.props.orderUpdate;
    const today = DateTime.now().startOf('day');
    const reservationDate = DateTime.fromISO(
      currentTickets[0].ticketOption.date
    ).startOf('day');

    this.state = {
      loading: true,
      currentTickets,
      curentQuantities: getQuantities(
        currentTickets.filter(({ ticketOption }) => ticketOption.addon)
      ),
      canUpdate: reservationDate > today,
    };
  }

  handleSetQuantity = (product, quantity) => {
    const { handleNewAddonsChanged } = this.props;
    const { newAddons } = this.props;

    const updatedAddons = newAddons.map((addon) => {
      if (addon.ticketOption.ticketOptionId === product.ticketOptionId) {
        return {
          ...addon,
          ticketOption: {
            ...addon.ticketOption,
            quantity: quantity,
          },
        };
      }
      return addon;
    });

    handleNewAddonsChanged(
      updatedAddons.filter(({ ticketOption }) => ticketOption.quantity > 0)
    );
  };

  renderAddNewAdons() {
    const { redirectToNewAddons } = this.props;
    return (
      <div className={this.getElementClass('new-addons')}>
        <p onClick={redirectToNewAddons}>Add</p>
        <Icon name="chevron-right" size="small" color="grey" />
      </div>
    );
  }

  render() {
    const { curentQuantities, canUpdate } = this.state;
    const {
      orderUpdate,
      ticketInventory,
      newAddons,
      newAddonsQuantities,
      reservationDate,
    } = this.props;
    const { newTickets, venue } = orderUpdate;
    const startTime = newTickets.length
      ? newTickets[0].ticketOption.startTime
      : null;
    const existingAddonsIds = newAddons.map(
      (addon) => addon.ticketOption.ticketOptionId
    );

    const addons = getInventory(reservationDate, ticketInventory, venue.id)
      ?.filter(
        (product) =>
          product.addon && existingAddonsIds.includes(product.ticketOptionId)
      )
      .map((addon) => ({
        ...addon,
        currentQuantity: getQuantity(addon, newAddonsQuantities),
        minimumQuantity: getQuantity(addon, curentQuantities),
        quantity: canUpdate
          ? addon.quantity
          : getQuantity(addon, newAddonsQuantities),
      }));

    return (
      <ChangeContainer title="Add-ons">
        {addons?.map((addon) => (
          <AddOnCardNew
            key={addon.ticketOptionId}
            product={addon}
            startTime={startTime}
            currentQuantity={addon.currentQuantity}
            minimumQuantity={addon.minimumQuantity}
            setQuantity={this.handleSetQuantity}
          />
        ))}

        {canUpdate && this.renderAddNewAdons()}
      </ChangeContainer>
    );
  }
}

AddOnSelector.propTypes = {
  newAddons: PropTypes.array.isRequired,
  newAddonsQuantities: PropTypes.object.isRequired,
  handleNewAddonsChanged: PropTypes.func.isRequired,
  redirectToNewAddons: PropTypes.func.isRequired,
  reservationDate: PropTypes.string,
};
