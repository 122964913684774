import { hot } from 'react-hot-loader/root';
import 'utils/sentry/init';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AuthSwitch } from 'common/routes';
import { identify } from 'utils/analytics';
import { getCookie } from 'utils/helpers/cookie';
import { setCookie } from 'utils/helpers/cookie';

import { SVGIcon, Video, ResponsiveImage } from 'common/components';

import {
  DesktopHeader,
  MobileNav,
  Footer,
  BookTickets,
  Notifications,
  ConsentBanner,
  NavigationHeader,
  NavigationFooter,
  MetaData,
} from 'public/components';

import {
  AngryBirds,
  AngryBirdsTicketInfo,
  AngryBirdsFaq,
  ArticleList,
  Article,
  Community,
  ContentPage,
  Directory,
  Entertainment,
  Food,
  HomeNew,
  Login,
  Logout,
  NickUniverseHome,
  NickUniverseTicketInfo,
  NickUniverseFaq,
  NotFound,
  ProfileTickets,
  ProfileOrders,
  ProfilePreferencesEmail,
  ProfilePreferencesPrivacy,
  RinkHome,
  RinkTicketInfo,
  RinkFaq,
  BigSnowHome,
  BigSnowTicketInfo,
  BigSnowFaq,
  DreamWorksHome,
  DreamWorksTicketInfo,
  DreamWorksFaq,
  DreamWorksMap,
  BlacklightHome,
  BlacklightTicketInfo,
  BlacklightFaq,
  MirrorMazeHome,
  MirrorMazeTicketInfo,
  MirrorMazeFaq,
  Services,
  Shop,
  Signup,
  SignupReferrer,
  RequestPassword,
  SetPassword,
  VenueDetails,
  Ride,
  VenueTicketInfo,
  VenueFaq,
  Visit,
  WifiHome,
  HoursAndDirections,
  Contact,
  DreamDebut,
  Careers,
  NewsletterSubscribe,
  DreamSafeHome,
  RollerCheckout,
  BundleTickets,
  BundleAddons,
  DreamGiftsGroupSales,
  OrderUpdate,
  OrderUpdateCheckout,
  OrderUpdateSuccess,
  OrderUpdateAddons,
  OrderUpdateBundleAddons,
  Deals,
  Chat,
  DealDetail,
  Sitemap,
  AdEvents,
  AdEventDetail,
  MiniGolfScoreHome,
  MiniGolfScorePlayers,
  MiniGolfScoreRules,
  MiniGolfScoreBoard,
  MiniGolfScoreWinner,
} from 'public/screens';
import TicketsScreenContainer from 'public/screens/NewCheckout/Tickets';
import AddonsScreenContainer from 'public/screens/NewCheckout/Addons';
import BasketScreenContainer from 'public/screens/NewCheckout/Basket';
import PaymentScreenContainer from 'public/screens/NewCheckout/Payment';
import CongratulationsScreenContainer from 'public/screens/NewCheckout/Congratulations';
import BirthdayTickets from 'public/screens/NewCheckout/Birthday/Birthday';
import BirthdayFormSubmitted from 'public/screens/NewCheckout/Birthday/BirthdayFormSubmitted/BirthdayFormSubmitted';
import BirthdayEntertainment from 'public/screens/NewCheckout/Birthday/Entertainment/BirthdayEntertainment';
import BirthdayMerchandise from 'public/screens/NewCheckout/Birthday/Merchandise/BirthdayMerchandise';
import BirthdayFoodAndBeverage from 'public/screens/NewCheckout/Birthday/BirthdayFoodAndBeverage/BirthdayFoodAndBeverage';
import BirthdayReview from 'public/screens/NewCheckout/Birthday/Review/Review';
import BirthdayInfo from 'public/screens/NewCheckout/Birthday/Info/Info';
import BirthdayPayment from 'public/screens/NewCheckout/Birthday/Payment/Payment';
import BirthdayPaymentConfirmation from 'public/screens/NewCheckout/Birthday/Payment/Confirmation/Confirmation';

import Preview from '../../screens/Preview';
import BrazeIframe from '../../components/BrazeIframe';
import { ChatIcon } from '../../components/ChatIcon';

// Icons
import ICON_URL from 'public/assets/icons.svg';
SVGIcon.setBaseUrl(ICON_URL);

// Static Images
import CONTENTFUL_IMAGES from 'public/assets/contentful-images.json';
ResponsiveImage.useContentfulImages(CONTENTFUL_IMAGES);
Video.useContentfulImages(CONTENTFUL_IMAGES);

// Excluded routes for Buy Tickets button
// It seems like the last regex was matching everything besides the homepage
// Now the homepage has a different book tickets button,
// So I'm excluding every route, and maybe we can even remove this logic (if the buy button should be just on the hp)
const excludedRoutes = [/.*/];

import './default-layout.less';

@inject('menu', 'announcements', 'appSession', 'me', 'betaFeatures')
@observer
class DefaultLayout extends React.Component {
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  assignUTMParams = () => {
    try {
      const utmParams = {};
      const searchParams = new URLSearchParams(document.location.search);
      utmParams.utm_source = searchParams.get('utm_source');
      utmParams.utm_medium = searchParams.get('utm_medium');
      utmParams.utm_campaign = searchParams.get('utm_campaign');
      utmParams.utm_id = searchParams.get('utm_id');
      utmParams.utm_term = searchParams.get('utm_term');
      utmParams.utm_content = searchParams.get('utm_content');
      if (Object.values(utmParams).every((param) => !param)) {
        if (document.referrer) {
          utmParams.utm_source = document.referrer;
          utmParams.utm_medium = 'Referral';
        } else {
          utmParams.utm_source = 'Direct';
        }
      }
      return setCookie('utmParams', JSON.stringify(utmParams), 60);
    } catch (e) {
      console.error(`Error assigning UTM params, Error:${e.message}`);
    }
  };

  async componentDidMount() {
    await this.props.announcements.search({
      type: [
        'nav-play',
        'nav-explore',
        'nav-shop',
        'footer',
        'homepage-spotlight',
        'banner',
      ],
    });
    await this.fetchUser();
    await this.props.menu.fetch();
    this.redirectNonProd();
    const isNavigationRedesignEnabled = await this.props.betaFeatures.fetch(
      'Navigation Redesign'
    );
    this.assignUTMParams();
    this.setState({ isNavigationRedesignEnabled });
  }

  redirectNonProd = () => {
    const isProd = window.__ENV__.API_URL.includes('americandream.com');
    if (isProd) return;

    if (getCookie('is_dev') === '1') return;

    if (location?.pathname?.split('/')?.[1] === 'preview') return;

    const { user } = this.props.me;
    if (!user?.roles.includes('admin'))
      window.location.href = 'https://americandream.com';
  };

  async fetchUser() {
    if (this.props.appSession.isLoggedIn()) {
      const user = await this.props.me.fetch();
      identify(user);
    } else {
      // The anonymous identify call appears to have an
      // effect on the FB pixel so need to keep it here.
      identify();
    }
  }

  render() {
    return (
      <React.Fragment>
        <MetaData pathname={this.props.location.pathname} />
        {this.state?.isNavigationRedesignEnabled !== undefined &&
          (this.state?.isNavigationRedesignEnabled ? (
            <NavigationHeader />
          ) : (
            this.props.menu.menu && (
              <>
                <DesktopHeader />
                <MobileNav />
              </>
            )
          ))}
        <main>
          <Notifications />
          <Switch>
            <Route path="/" component={HomeNew} exact />
            <Route path="/sitemap" component={Sitemap} exact />
            <Route path="/shop" component={Shop} exact />
            <Route path="/visit" component={Visit} exact />
            <Route path="/entertainment" component={Entertainment} />
            <Route path="/food-and-drink" component={Food} />
            <Route path="/services" component={Services} />
            <Route path="/cart/checkout" component={PaymentScreenContainer} />
            <Route
              path="/cart/completed"
              component={CongratulationsScreenContainer}
            />
            <Route path="/cart" component={BasketScreenContainer} exact />
            <Route path="/articles/:category" component={ArticleList} />
            <Route path="/article/:slug" component={Article} />
            <Route path="/directory/:type?/:flag?" component={Directory} />
            {/* <Route path="/preview/:id" component={ContentPreview} /> */}
            <Route
              exact
              path="/events/category/:category?"
              component={AdEvents}
            />
            <Route path="/events/:slug" component={AdEventDetail} />
            <Route path="/community" component={Community} />
            <Route
              exact
              path="/deals/category/:currentCategory?"
              component={Deals}
            />
            <Route path="/deals/:slug" component={DealDetail} />

            <Route
              path="/venue/nickelodeon-universe"
              component={NickUniverseHome}
              exact
            />
            <Route
              path="/venue/nickelodeon-universe/ticket-info"
              component={NickUniverseTicketInfo}
              exact
            />
            <Route
              path="/venue/nickelodeon-universe/faq"
              component={NickUniverseFaq}
              exact
            />

            <Route path="/venue/the-rink" component={RinkHome} exact />
            <Route
              path="/venue/the-rink/ticket-info"
              component={RinkTicketInfo}
              exact
            />
            <Route path="/venue/the-rink/faq" component={RinkFaq} exact />

            <Route path="/venue/big-snow" component={BigSnowHome} exact />
            <Route
              path="/venue/big-snow/ticket-info"
              component={BigSnowTicketInfo}
              exact
            />
            <Route path="/venue/big-snow/faq" component={BigSnowFaq} exact />

            <Route
              path="/venue/dreamworks-water-park"
              component={DreamWorksHome}
              exact
            />
            <Route
              path="/venue/dreamworks-water-park/ticket-info"
              component={DreamWorksTicketInfo}
              exact
            />
            <Route
              path="/venue/dreamworks-water-park/faq"
              component={DreamWorksFaq}
              exact
            />
            <Route
              path="/venue/dreamworks-water-park/map"
              component={DreamWorksMap}
              exact
            />

            <Route
              path="/venue/blacklight-mini-golf"
              component={BlacklightHome}
              exact
            />
            <Route
              path="/venue/blacklight-mini-golf/ticket-info"
              component={BlacklightTicketInfo}
              exact
            />
            <Route
              path="/venue/blacklight-mini-golf/faq"
              component={BlacklightFaq}
              exact
            />
            <Route path="/venue/mirrormaze" component={MirrorMazeHome} exact />
            <Route
              path="/venue/mirrormaze/ticket-info"
              component={MirrorMazeTicketInfo}
              exact
            />
            <Route
              path="/venue/mirrormaze/faq"
              component={MirrorMazeFaq}
              exact
            />
            <Route
              path="/venue/angry-birds-mini-golf"
              component={AngryBirds}
              exact
            />
            <Route
              path="/venue/angry-birds-mini-golf/ticket-info"
              component={AngryBirdsTicketInfo}
              exact
            />
            <Route
              path="/venue/angry-birds-mini-golf/faq"
              component={AngryBirdsFaq}
              exact
            />

            <Route path="/venue/:slug" component={VenueDetails} exact />

            <Route path="/bundle/:slug/addons" component={BundleAddons} />

            <Route path="/bundle/:slug" component={BundleTickets} />

            <Route
              path="/birthday/:slug/tickets/:date?"
              component={BirthdayTickets}
            />

            <Route
              path="/birthday/:slug/form-submitted"
              component={BirthdayFormSubmitted}
            />

            <Route
              path="/birthday/:slug/addons"
              component={BirthdayEntertainment}
            />

            <Route
              path="/birthday/:slug/merchandise"
              component={BirthdayMerchandise}
            />

            <Route
              path="/birthday/:slug/food-and-beverage"
              component={BirthdayFoodAndBeverage}
            />

            <Route path="/birthday/:slug/review" component={BirthdayReview} />

            <Route path="/birthday/:slug/info" component={BirthdayInfo} />

            <Route path="/birthday/:slug/payment" component={BirthdayPayment} />

            <Route path="/preview/:env/:entryId" component={Preview} />

            <Route
              path="/birthday/:slug/payment-confirmed"
              component={BirthdayPaymentConfirmation}
            />

            <Route
              path="*/:slug/tickets/:date?"
              component={TicketsScreenContainer}
            />

            <Route path="*/:slug/addons" component={AddonsScreenContainer} />

            <Route
              path="/venue/:slug/ticket-info"
              component={VenueTicketInfo}
            />

            <Route path="/venue/:slug/faq" component={VenueFaq} />

            <Route path="/venue/:slug/attraction/:rideSlug" component={Ride} />

            <Route
              path="/influencer-tour"
              render={(props) => (
                <DreamGiftsGroupSales
                  brazeTriggers={{
                    customAttributes: {
                      'groupsales-Influencer': 'yes',
                    },
                    customEvent: 'subscription groupsales form-Influencer',
                  }}
                  {...props}
                />
              )}
              exact
            />
            <Route
              path="/shopping-tour"
              render={(props) => (
                <DreamGiftsGroupSales
                  brazeTriggers={{
                    customAttributes: {
                      'groupsales-ShoppingTour': 'yes',
                    },
                    customEvent: 'subscription groupsales form-ShoppingTour',
                  }}
                  {...props}
                />
              )}
              exact
            />
            <Route
              path="/dreamworks-water-park-after-dark"
              render={(props) => (
                <DreamGiftsGroupSales
                  brazeTriggers={{
                    customAttributes: {
                      'groupsales-AfterDark': 'yes',
                    },
                    customEvent: 'subscription groupsales form-AfterDark',
                  }}
                  {...props}
                />
              )}
              exact
            />

            <Route
              path="/promo/:slug/addons"
              component={AddonsScreenContainer}
            />

            <Route
              path="/promo/:slug/:date?"
              component={TicketsScreenContainer}
            />

            <Route path="/dreamsafe" component={DreamSafeHome} />

            <AuthSwitch
              path="/order-update"
              loggedIn={OrderUpdate}
              loggedOut={() => <Redirect to="/signup" />}
              exact
            />

            <AuthSwitch
              path="/order-update-addons"
              loggedIn={OrderUpdateAddons}
              loggedOut={() => <Redirect to="/signup" />}
              exact
            />

            <AuthSwitch
              path="/order-update-bundle-addons"
              loggedIn={OrderUpdateBundleAddons}
              loggedOut={() => <Redirect to="/signup" />}
              exact
            />

            <AuthSwitch
              path="/order-update-checkout"
              loggedIn={OrderUpdateCheckout}
              loggedOut={() => <Redirect to="/signup" />}
              exact
            />

            <AuthSwitch
              path="/order-update-success"
              loggedIn={OrderUpdateSuccess}
              loggedOut={() => <Redirect to="/signup" />}
              exact
            />

            <AuthSwitch
              path="/profile/tickets"
              loggedIn={ProfileTickets}
              loggedOut={() => <Redirect to="/signup" />}
              exact
            />

            <AuthSwitch
              path="/profile/orders"
              loggedIn={ProfileOrders}
              loggedOut={() => <Redirect to="/signup" />}
              exact
            />

            <AuthSwitch
              path="/profile/preferences/email"
              loggedIn={ProfilePreferencesEmail}
              loggedOut={() => <Redirect to="/signup" />}
              exact
            />
            <AuthSwitch
              path="/profile/preferences/privacy"
              loggedIn={ProfilePreferencesPrivacy}
              loggedOut={() => <Redirect to="/signup" />}
              exact
            />
            <AuthSwitch
              path="/profile"
              loggedIn={() => <Redirect to="/profile/orders" />}
              loggedOut={() => <Redirect to="/login" />}
              exact
            />
            <AuthSwitch
              exact
              path="/signup"
              loggedOut={Signup}
              loggedIn={() => <Redirect to="/profile" />}
            />
            <Route
              exact
              path="/signup/referrer/:code"
              component={SignupReferrer}
            />
            <AuthSwitch
              exact
              path="/login"
              loggedOut={Login}
              loggedIn={() => <Redirect to="/profile" />}
            />
            <AuthSwitch
              exact
              path="/forgot-password"
              loggedOut={RequestPassword}
              loggedIn={() => <Redirect to="/profile" />}
            />
            <AuthSwitch
              exact
              path="/reset-password"
              loggedOut={SetPassword}
              loggedIn={() => <Redirect to="/profile" />}
            />
            <Route
              exact
              path="/signup/referrer/:code"
              component={SignupReferrer}
            />

            <Route exact path="/logout" component={Logout} />
            <Route exact path="/wifi/welcome" component={WifiHome} />
            <Route
              path="/hours-and-directions"
              component={HoursAndDirections}
            />
            <Route path="/contact" component={Contact} />

            <Route path="/chat" component={Chat} />

            <Route path="/dream-debut" component={DreamDebut} />

            <Route path="/tenant-careers" component={Careers} />

            <Route
              path="/venue/:slug/score-card/players"
              component={MiniGolfScorePlayers}
            />

            <Route
              path="/venue/:slug/score-card/rules"
              component={MiniGolfScoreRules}
            />

            <Route
              path="/venue/:slug/score-card/board"
              component={MiniGolfScoreBoard}
            />

            <Route
              path="/venue/:slug/score-card/winner"
              component={MiniGolfScoreWinner}
            />

            <Route
              path="/venue/:slug/score-card"
              component={MiniGolfScoreHome}
            />

            <Redirect path="/news" to="/newsletter" exact />
            <Redirect
              path="/newsletter/subscribe/notify"
              to="/newsletter"
              exact
            />

            <Redirect
              path="/newsletter/subscribe/waterpark"
              to="/newsletter/subscribe/dreamworks"
              exact
            />
            <Redirect path="/newsletter/subscribe/" to="/newsletter" exact />

            <AuthSwitch
              path="/newsletter/subscribe/:group"
              loggedIn={() => <Redirect to="/profile/preferences/email" />}
              loggedOut={NewsletterSubscribe}
              exact
            />

            <Redirect
              path="/venue/:slug/pricing"
              to="/venue/:slug/tickets"
              exact
            />

            <Redirect path="/thrills-and-chills" to="/" exact />
            <Redirect path="/make-a-splash" to="/" exact />
            <Redirect path="/every-day-is-a-snow-day" to="/" exact />

            <Route
              path="/tickets/:checkoutslug"
              component={RollerCheckout}
              exact
            />

            <Route
              path="/redeem/:checkoutslug"
              component={RollerCheckout}
              exact
            />

            <Route path="/buy/:checkoutslug" component={RollerCheckout} exact />

            <Redirect from="/about" to="/our-story" />
            <Route path="/:slug" component={ContentPage} />
            <Route component={NotFound} />
          </Switch>
        </main>
        {this.state?.isNavigationRedesignEnabled ? (
          <NavigationFooter />
        ) : (
          <Footer />
        )}
        <BookTickets excludedRoutes={excludedRoutes} floatingComponent={true} />
        <BrazeIframe />
        <ConsentBanner />
        <ChatIcon />
      </React.Fragment>
    );
  }
}

export default hot(DefaultLayout);
