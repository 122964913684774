import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { inject } from 'mobx-react';
import { Container, Loader } from 'semantic-ui-react';

import { Spacer, DirectoryBar } from 'public/components';
import { useClassName } from 'common/hooks';
import { SectionTitle } from '../Directory/components';

import DealList from './components/DealList';
import Categories from './components/Categories';
import DealsPagination from './components/DealsPagination';
import FeaturedDeals from './components/FeaturedDeals';
import { DesktopOnly } from 'common/components';

import './deals.less';

const ITEMS_PER_PAGE = 20;

const Deals = (props) => {
  let { currentCategory = 'all-deals' } = useParams();
  const slideRef = useRef();
  const [deals, setDeals] = useState(null);
  const [featured, setFeatured] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const className = useClassName('DealsPage');

  useEffect(() => {
    getFeatured();
  }, []);

  useEffect(() => {
    fetchData();
  }, [currentCategory, page, searchInput]);

  useEffect(() => {
    setPage(1);
  }, [currentCategory, searchInput]);

  const getFeatured = async () => {
    const featuredDeals = (await props.deals.getFeaturedDeals()) || [];
    setFeatured(featuredDeals);
  };

  const fetchData = async () => {
    try {
      const [data, totalItems] = await props.deals.getAllDeals({
        subCategory:
          subCategories?.find((category) => category.route === currentCategory)
            ?.name || '',
        skip: (page - 1) * ITEMS_PER_PAGE,
        limit: ITEMS_PER_PAGE,
        query: searchInput,
      });
      const newSubCategories = await props.deals.getCategories(searchInput);
      setDeals([...data]);
      setTotalItems(totalItems);
      setSubCategories([...newSubCategories]);
    } catch (e) {
      console.error(`Could not retrieve deals, Error:${e.message}`);
    }
  };

  return (
    <Container className={className('deals-page-wrapper')}>
      <div className={className('header-wrapper')}>
        <SectionTitle title="Discover all what we have to offer" />
      </div>
      <DirectoryBar
        handleSearchChange={setSearchInput}
        searchPlaceHolder={'Search deals'}
      />
      {featured?.length > 0 && (
        <>
          {searchInput === '' && (
            <>
              <FeaturedDeals
                featuredItems={featured}
                slideRef={slideRef}
                show={true}
              />
              <DesktopOnly>
                <Spacer size="m" />
              </DesktopOnly>
            </>
          )}
        </>
      )}
      {deals == null && <Loader inline="centered" active />}
      {deals?.length === 0 && <span>There's no deals or promo.</span>}

      {deals?.length > 0 && (
        <>
          <Categories
            categories={subCategories || []}
            currentCategory={currentCategory}
          />
          <Spacer size="m" />
          <DealList dealItems={deals} />
          <Spacer size="m" />
          <DealsPagination
            deals={deals}
            page={page}
            totalItems={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            handlePageChange={setPage}
          />
        </>
      )}
    </Container>
  );
};

Deals.propTypes = {
  props: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export default inject('deals')(Deals);
