import React from 'react';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Container, Button, Loader } from 'semantic-ui-react';
import { Screen, withVenue } from 'public/helpers';
import { getVenueDetailType } from 'public/helpers/contentful/parser';
import { Spacer, OpenStatus, NearbyVenues } from 'public/components';
import {
  Layout,
  ExternalLink,
  ContentfulImage,
  ContentfulRichText,
  ContentfulPlainText,
  SVGIcon as Icon,
  MapKitMap as Map,
  getContentfulField as getField,
} from 'common/components';
import { AttractionScreen, VenueDetailsScreen } from 'public/components';
import { ViewHours } from 'public/components/ViewHours';

import './details.less';
import { NotFound } from '../../components/NotFound';
import VenueDetailsBreadcrumb from '../../components/VenueDetailsBreadcrumb';

import CustomLandingPage from '../CustomLandingPage';
import { unwrapContentfulFields } from '../../../common/components';

@inject('venues', 'pages')
@observer
@withVenue
export default class VenueDetails extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      focusedAmenity: null,
      focusedFloor: props.venue.floorLevel || 1,
      relatedVenues: [],
      loading: true,
    };
  }

  routeDidUpdate() {
    const { params } = this.props.match;
    this.props.pages
      .fetchItemBySlug(params.slug)
      .then((customLandingPage) => {
        this.setState({
          loading: false,
          customLandingPage,
        });
      })
      .catch(() => {
        this.loadEvents();
        this.getVenuesList();
        this.props.onRouteDidUpdate();
      });
  }

  async getVenuesList() {
    const { venue } = this.props;

    if (!venue) return null;

    if (venue?.content?.venueDetail) {
      const type = getVenueDetailType(venue.content.venueDetail);

      const relatedVenues = type
        ? await this.props.venues.fetchVenuesByType(type)
        : [];

      this.setState({
        loading: false,
        relatedVenues,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  async loadEvents() {
    const { fetchEvents } = this.props;
    let events;
    if (fetchEvents) {
      events = await fetchEvents();
    } else {
      events = await this.props.events.fetchItems({
        venueId: this.props.venue.id,
        limit: 1,
      });
    }
    this.setState({
      events: events,
    });
  }

  onFloorUpdate = (floor) => {
    this.setState({
      focusedFloor: floor,
    });
  };

  onAmenityClick = (amenity) => {
    if (amenity === this.state.focusedAmenity) {
      amenity = null;
      this.annotation.selected = true;
    } else {
      this.annotation.selected = false;
    }
    this.setState({
      focusedAmenity: amenity,
    });
  };

  onAnnotationAdded = (annotation) => {
    this.annotation = annotation;
  };

  renderBody() {
    const { error, venue } = this.props;

    if (error === 'Not Found') {
      return <NotFound />;
    } else if (!venue || this.state.loading) {
      return (
        <>
          <Spacer size="xl" />
          <Loader inline="centered" active />
          <Spacer size="xl" />
        </>
      );
    }

    if (this.state.customLandingPage) {
      return (
        <CustomLandingPage
          page={unwrapContentfulFields(this.state.customLandingPage.object)}
        />
      );
    }

    if (venue.content.attractionScreen) {
      return <AttractionScreen venue={venue} />;
    }

    if (venue.content.venueDetail) {
      return (
        <VenueDetailsScreen
          venue={venue}
          relatedVenues={this.state.relatedVenues}
        />
      );
    }

    return (
      <div {...this.getAttrs()}>
        <VenueDetailsBreadcrumb
          type={venue.venueType}
          title={getField(venue.content.name)}
        />
        <Layout horizontal center stackable>
          <Layout.Group className={this.getElementClass('image')} fixed>
            <ContentfulImage
              field={venue.content.hero || venue.content.horizontalImage}
              fluid
            />
          </Layout.Group>
          <Layout.Group grow>
            <div className={this.getElementClass('content')}>
              <h1 className={this.getElementClass('title')}>
                <ContentfulPlainText field={venue.content.name} />
              </h1>
              <Spacer size="xs" />
              {this.renderOpenHours()}
              <Spacer size="s" />
              {this.renderWebsite()}
            </div>
          </Layout.Group>
        </Layout>
        <Container>
          <Spacer size="m" />
          <div className={this.getElementClass('description')}>
            <ContentfulRichText field={venue.content.description} />
          </div>
          <Spacer size="m" />
        </Container>
        {this.renderMap()}
        <Container>
          {this.renderEvents()}
          <Spacer size="m" />
          <NearbyVenues venue={venue} />
          <Spacer size="l" />
        </Container>
      </div>
    );
  }

  renderOpenHours() {
    const { openingHours, featuredFlags, content } = this.props.venue;

    if (featuredFlags.includes('Coming Soon')) {
      return 'Coming Soon!';
    } else if (openingHours) {
      return (
        <React.Fragment>
          <OpenStatus openingHours={openingHours} />
          <ViewHours
            openingHours={openingHours}
            closedMessage={content.closedMessage}
          />
        </React.Fragment>
      );
    }
  }

  renderWebsite() {
    const { contactDetails } = this.props.venue;
    const website = contactDetails && contactDetails.website;
    if (website) {
      return (
        <div>
          <Button as={ExternalLink} href={website}>
            <Icon name="external-link" size="mini" />
            <span>Website</span>
          </Button>
        </div>
      );
    }
  }

  renderMap() {
    const { venue } = this.props;
    if (venue.location) {
      const { lat, lng } = venue.location;
      return (
        <Map
          className={this.getElementClass('map')}
          annotation={{
            lat,
            lng,
            floor: venue.floorLevel,
            renderCallout: this.renderMapCallout,
            selected: true,
          }}
          focusedVenue={venue}
          focusedFloor={this.state.focusedFloor}
          focusedAmenity={this.state.focusedAmenity}
          onFloorUpdate={this.onFloorUpdate}
          onAmenityClick={this.onAmenityClick}
          onAnnotationAdded={this.onAnnotationAdded}
          renderAmenityButtons
          renderZoomButtons
          removeControls
        />
      );
    }
  }

  renderMapCallout = () => {
    const { venue } = this.props;
    return (
      <div>
        <p className={this.getElementClass('callout-title')}>{venue.name}</p>
        <p className={this.getElementClass('callout-subtitle')}>
          {venue.locationDescription}
        </p>
      </div>
    );
  };

  renderEvents() {
    const { events } = this.state;
    return events.map((event, i) => {
      const { horizontalImage, category, body } = event.object.fields;
      return (
        <div className={this.getElementClass('event')} key={i}>
          <Spacer size="m" />
          <Link
            to={`/event/${event.slug}`}
            className={this.getElementClass('event')}>
            <Layout horizontal top padded stackable>
              <Layout.Group>
                <ContentfulImage
                  className={this.getElementClass('event-image')}
                  field={horizontalImage}
                />
              </Layout.Group>
              <Layout.Group>
                <div className={this.getElementClass('headline')}>
                  <ContentfulPlainText field={category} />
                </div>
                <Spacer size="xs" />
                <ContentfulRichText field={body} />
                <Spacer size="xs" />
                <p className={this.getElementClass('more-info')}>
                  <span>More Info</span> <Icon name="angle-right" size="mini" />
                </p>
              </Layout.Group>
            </Layout>
          </Link>
        </div>
      );
    });
  }
}
