import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useClassName, useWindowSize } from 'common/hooks';
import ViewTypeOptions from './ViewTypeOptions';
import { SVGIcon as Icon, Dropdown } from 'common/components';

import './event-calendar-header.less';

const EventCalendarHeader = ({
  viewType,
  handleViewTypeChange,
  calendarRef,
}) => {
  const className = useClassName('EventCalendarHeader');
  const today = DateTime.now();
  const calendarAPI = calendarRef?.current?.getApi(today.month);
  const [currentMonth, setCurrentMonth] = useState(today.month - 1);
  const { isMobile } = useWindowSize();
  const monthsDropDownValues = new Array(12).fill().map((val, index) => {
    const monthValue = today.month + index;
    const month = Math.abs(monthValue >= 12 ? 12 - monthValue : monthValue);
    return {
      value: month - 1,
      label: DateTime.now().set({ month: month }).toFormat('MMMM'),
    };
  });

  const handleNext = () => {
    calendarAPI?.next();
    updateMonth();
  };

  const handlePrev = () => {
    calendarAPI?.prev();
    updateMonth();
  };

  const handleTodayClick = () => {
    calendarAPI?.today();
    updateMonth();
  };

  const handleMonthChange = (selected) => {
    const newMonth = selected + 1;
    const newDate = DateTime.now()
      .startOf('month')
      .set({
        month: newMonth,
        year: newMonth < today.month ? today.year + 1 : today.year,
      })
      .toISODate();
    calendarAPI.gotoDate(newDate);
    updateMonth();
  };

  const updateMonth = () => setCurrentMonth(calendarAPI.getDate().getMonth());

  return (
    <div className={className('container')}>
      <div className={className('controls')}>
        <div className={className('arrow-controls')}>
          <button
            className={className('rounded-btn', 'prev')}
            onClick={handlePrev}>
            <Icon name="chevron-left-small" size={isMobile ? 'mini' : 'tiny'} />
          </button>
          <button
            onClick={handleNext}
            className={className('rounded-btn', 'next')}>
            <Icon name="chevron-left-small" size={isMobile ? 'mini' : 'tiny'} />
          </button>
        </div>

        <div className={className('month-controls')}>
          <button onClick={handleTodayClick} className={className('today-btn')}>
            Today
          </button>
          <Dropdown
            options={monthsDropDownValues}
            selected={currentMonth}
            handleChange={handleMonthChange}
          />
        </div>
      </div>
      {!isMobile && (
        <ViewTypeOptions
          viewType={viewType}
          handleViewTypeChange={handleViewTypeChange}
        />
      )}
    </div>
  );
};

EventCalendarHeader.propTypes = {
  viewType: PropTypes.string,
  handleViewTypeChange: PropTypes.func,
  calendarRef: PropTypes.object,
};

export default EventCalendarHeader;
