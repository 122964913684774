import React from 'react';
import { Loader } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { NotFound } from '../components/NotFound';

function createWrapper(Component, slug) {
  @inject('venues', 'events')
  @observer
  class WithVenueComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        venue: props.preview,
        error: null,
      };
    }

    onRouteDidUpdate = () => {
      this.fetchVenue();
    };

    componentDidMount() {
      this.fetchVenue();
    }

    getSlug() {
      const { params } = this.props.match;
      return slug || params.slug;
    }

    async fetchVenue() {
      if (this.props.preview) {
        // Venue may be passed in by the preview screen.
        return;
      }
      try {
        const venue = await this.props.venues.fetchItemBySlug(this.getSlug());
        if (!venue || !venue.content) {
          this.setState({
            error: 'Not Found',
          });
          return;
        }

        this.setState({
          venue,
        });
      } catch (err) {
        this.setState({
          error: err.message,
        });
      }
    }

    render() {
      const { error, venue } = this.state;
      if (error === 'Not Found') {
        return <NotFound />;
      } else if (!venue) {
        return <Loader active />;
      }
      return (
        <Component
          {...this.props}
          venue={venue}
          status={status}
          onRouteDidUpdate={this.onRouteDidUpdate}
        />
      );
    }
  }

  return WithVenueComponent;
}

export default function withVenue(arg) {
  if (typeof arg === 'string') {
    return function (Component) {
      return createWrapper(Component, arg);
    };
  } else {
    return createWrapper(arg);
  }
}
