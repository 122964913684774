import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import { DateTime } from 'luxon';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useClassName, useWindowSize } from 'common/hooks';
import { getCalendarParsedEvents } from '../utils';
import EventCalendarHeader from './EventCalendarHeader';
import CalendarEvent from './CalendarEvent';
import EventList from './EventList';

import './event-calendar.less';

const EventCalendar = ({
  events,
  viewType,
  setSelected,
  searchInput,
  handleViewTypeChange,
}) => {
  const calendarRef = useRef();
  const className = useClassName('EventCalendar');
  const [selectedDate, setSelectedDate] = useState(null);
  const { isMobile } = useWindowSize();

  const parsedEvents = getCalendarParsedEvents(events);

  const searchingOnMobile = () => searchInput && isMobile;

  const handleEventClick = (eventInfo) => {
    if (isMobile) {
      calendarRef.current.getApi().select(eventInfo.event.start);
      return setSelectedDate(DateTime.fromJSDate(eventInfo.event.start));
    }
    const schedule = eventInfo.event.extendedProps.eventData.schedule;

    setSelected({
      ...eventInfo.event.extendedProps.eventData.event,
      schedule,
      weekdays: schedule.weekdays,
      selectedDate: DateTime.fromJSDate(eventInfo.event.start),
    });
  };

  const sortEvents = (a, b) => {
    if (a.isFeatured && !b.isFeatured) return -1;
    if (a.start > b.start) return -1;
    return 1;
  };

  const renderEventContent = (eventInfo) => (
    <CalendarEvent eventInfo={eventInfo} setSelected={setSelected} />
  );

  return (
    <div className={className('container')}>
      <div
        className={className([
          'wrapper',
          `wrapper-${searchingOnMobile() ? 'hidden' : 'visible'}`,
        ])}>
        <EventCalendarHeader
          viewType={viewType}
          handleViewTypeChange={handleViewTypeChange}
          calendarRef={calendarRef}
        />
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          headerToolbar={null}
          events={parsedEvents}
          eventContent={renderEventContent}
          contentHeight="auto"
          dayMaxEvents={isMobile ? 999 : 2}
          eventClick={handleEventClick}
          eventOrder={sortEvents}
          selectable={true}
        />
      </div>
      {isMobile && (
        <EventList
          eventItems={events}
          setSelected={setSelected}
          selectedDate={searchingOnMobile() ? null : selectedDate}
          viewType={viewType}
          handleViewTypeChange={handleViewTypeChange}
        />
      )}
    </div>
  );
};

EventCalendar.propTypes = {
  events: PropTypes.array.isRequired,
  viewType: PropTypes.string,
  searchInput: PropTypes.string,
  setSelected: PropTypes.func,
  handleViewTypeChange: PropTypes.func,
};

export default EventCalendar;
