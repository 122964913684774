import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { TabletOnly, MobileOnly } from 'common/components';
import Hero from './Hero';
import WelcomeSection from './WelcomeSection';
import { FactsSection } from './FactsSection';
import { RidesSection } from './RidesSection';
import { ReviewsSection } from './ReviewsSection';
import { TicketsSection } from './TicketsSection';
import { InfoSection } from './InfoSection';
import ExploreSection from './ExploreSection';
import TitleSection from './TitleSection';
import AttractionScreenDivider from './AttractionScreenDivider';
import { useClassName } from 'common/hooks';
import { parseAttraction } from 'public/helpers/contentful/parser';
import { trackHotjar, trackEpsilon } from 'utils/analytics';
import './attraction-screen.less';

const AttractionScreen = ({ me, venue, appSession }) => {
  const className = useClassName('AttractionScreen');
  const content = parseAttraction(venue.content.attractionScreen);

  useEffect(() => {
    const asyncTrack = async () => {
      await trackEpsilon(me, appSession, 2, 101, 'attraction');
    };
    trackHotjar(`Attraction Screen Viewed: ${venue.name}`);
    window.scrollTo(0, 0);
    asyncTrack();
  }, []);

  return (
    <div className={className('container')}>
      <Hero
        id={'hero'}
        venue={venue}
        heroLogo={content.heroLogo}
        heroTitle={content.heroTitle}
        heroVideo={content.heroVideo}
        heroPoster={content.heroPoster}
        heroSubtitle={content.heroSubtitle}
        heroCtaText={content.heroCtaText}
        heroCtaLink={content.heroCtaLink}
        theme={content.theme}
        ticketOptions={content.ticketOptions}
        ridesCards={content.ridesCards}
      />
      <WelcomeSection
        id={'welcome'}
        primaryTitle={content.welcomeSectionPrimaryTitle}
        secondaryTitle={content.welcomeSectionSecondaryTitle}
        imageMobile={content.welcomeSectionImageMobile}
        imageDesktop={content.welcomeSectionImageDesktop}
        theme={content.theme}
      />
      <ExploreSection
        id={'explore'}
        title={content.exploreSectionTitle}
        imageMobile={content.exploreSectionImageMobile}
        imageTablet={content.exploreSectionImageTablet}
        imageDesktop={content.exploreSectionImageDesktop}
        theme={content.theme}
      />
      <TitleSection
        id={'facts'}
        titleFirstPart={content.factsSectionTitleFirstPart}
        titleSecondPart={content.factsSectionTitleSecondPart}
        image={content.factsSectionImage}
        titleFirstPartColor={
          content.theme?.customDarkTitleColor || content.theme?.secondaryColor
        }
        titleSecondPartColor={
          content.theme?.customDarkTitleColor || content.theme?.primaryColor
        }
      />
      <FactsSection id={'facts-cards'} cards={content.factsCards} />
      <TitleSection
        id={'rides'}
        titleFirstPart={content.ridesSectionTitleFirstPart}
        titleSecondPart={content.ridesSectionTitleSecondPart}
        image={content.ridesSectionImage}
        backgroundColor={content.theme?.secondaryColor}
        titleFirstPartColor={content.theme?.textColor}
        titleSecondPartColor={content.theme?.buttonColor}
      />
      <RidesSection
        id={'rides-cards'}
        cards={content.ridesCards}
        categories={content.ridesCategories}
        theme={content.theme}
      />
      {content.showCustomerReviews && content.ridesCategories && (
        <AttractionScreenDivider color={content.theme?.primaryColor} />
      )}
      {content.showCustomerReviews && (
        <>
          <ReviewsSection
            id={'reviews'}
            title={content.reviewsSectionTitle}
            titleColor={
              content.theme?.customDarkTitleColor ||
              content.theme?.secondaryColor
            }
            image={content.reviewsSectionImage}
            cards={content.reviewsSectionCards}
          />
          <TabletOnly className={className('divider-container')}>
            <AttractionScreenDivider background={content.theme?.primaryColor} />
          </TabletOnly>
          <MobileOnly className={className('divider-container')}>
            <AttractionScreenDivider background={content.theme?.primaryColor} />
          </MobileOnly>
        </>
      )}
      <TicketsSection
        id={'tickets'}
        ticketsSectionTitle={content.ticketsSectionTitle}
        ticketsSectionSubtitle={content.ticketsSectionSubtitle}
        ticketsSectionImage={content.ticketsSectionImage}
        ticketsSectionCtaText={content.ticketsSectionCtaText}
        ticketsSectionCtaLink={content.ticketsSectionCtaLink}
        theme={content.theme}
      />
      {content.showInfoSection && (
        <InfoSection
          id={'info'}
          infoSectionTitle={content.infoSectionTitle}
          openHoursTitle={content.openHoursTitle}
          openHoursIcon={content.openHoursIcon}
          openHours={venue?.openingHours}
          featuredFlags={venue?.featuredFlags}
          closedMessage={venue?.content?.closedMessage}
          alertsTitle={content.alertsTitle}
          alertsIcon={content.alertsIcon}
          alerts={venue?.content?.alerts}
          infoLinks={content.infoLinks}
        />
      )}
    </div>
  );
};

AttractionScreen.propTypes = {
  venue: PropTypes.shape({
    content: PropTypes.shape({
      attractionScreen: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
};

export default inject('me', 'appSession')(AttractionScreen);
