import { DateTime } from 'luxon';

const normalize = (text) => {
  return text
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '');
};

const createRoute = (str) => {
  return str
    .replaceAll(/[^0-9a-z\s+]/gi, '')
    .replace(/ +/g, '-')
    .trim()
    .toLowerCase();
};

const isPast = (schedule) => {
  const today = DateTime.fromObject({
    zone: 'America/New_York',
  }).startOf('day');

  return today > schedule.endDate;
};

const getSchedule = (event) => {
  const schedules = event.schedules.map((a) => ({
    ...a,
    startDate: DateTime.fromFormat(a.startDate, 'yyyy-MM-dd', {
      zone: 'America/New_York',
    }),
    endDate: DateTime.fromFormat(a.endDate, 'yyyy-MM-dd', {
      zone: 'America/New_York',
    }),
  }));

  return (
    schedules.find(
      (schedule, index) => !isPast(schedule) || index === schedule.length - 1
    ) || schedules[schedules.length - 1]
  );
};

const getCalendarParsedEvents = (events) => {
  const today = DateTime.fromObject({
    zone: 'America/New_York',
  }).startOf('day');

  return events
    .map((event) => {
      const schedules = [];
      event.schedules.forEach((schedule) => {
        const startDate = schedule.startDate.startOf('day');
        const endDate = schedule.endDate.startOf('day');
        for (
          let date = startDate;
          date <= endDate;
          date = date.plus({ days: 1 })
        ) {
          if (date < today) continue;
          const day = schedule.weekdays.find(
            (weekday) =>
              weekday.weekday.toUpperCase() ===
              date.toFormat('EEEE').toUpperCase()
          );
          if (!day || day?.isClosed) continue;
          const openHour = day.openHour || 0;
          const openMinute = day.openMinute || 0;
          const closeHour = day.closeHour || 23;
          const closeMinute = isNaN(day.closeMinute) ? 59 : day.closeMinute;
          const startDateTime = DateTime.fromISO(date.toISODate()).set({
            hour: openHour,
            minute: openMinute,
          });
          const endDateTime = DateTime.fromISO(date.toISODate()).set({
            hour: closeHour,
            minute: closeMinute,
          });
          schedules.push({
            title: event.title,
            start: startDateTime.toFormat("yyyy-LL-dd'T'hh:mm:ss"),
            end: endDateTime.toFormat("yyyy-LL-dd'T'hh:mm:ss"),
            backgroundColor: 'none',
            editable: false,
            extendedProps: {
              hours: `${startDateTime.toFormat('ha')} - ${endDateTime.toFormat(
                'ha'
              )}`,
              isFeatured: event.isFeatured,
              eventData: {
                event,
                schedule,
              },
            },
          });
        }
      });
      return schedules;
    })
    .flat();
};

const filterDateEvents = (selectedDate, events) => {
  if (!selectedDate) return events;
  return events.filter((event) =>
    event.schedules.some((schedule) => {
      const day = schedule.weekdays.find(
        (weekday) =>
          weekday.weekday.toUpperCase() ===
          selectedDate.toFormat('EEEE').toUpperCase()
      );
      if (!day || day?.isClosed) return false;
      if (
        selectedDate.setZone('America/New_York').startOf('day') <=
          schedule.endDate.endOf('day') &&
        selectedDate.setZone('America/New_York').startOf('day') >=
          schedule.startDate.startOf('day')
      )
        return true;
      return false;
    })
  );
};

const getScheduleWeekDay = (schedule, selectedDate) =>
  schedule.weekdays.find(
    (weekday) =>
      weekday.weekday.toUpperCase() ===
      selectedDate.toFormat('EEEE').toUpperCase()
  );

const getTimeValue = (value) => (isNaN(value) ? 0 : value);

const getAddToCalendarEvent = (event, selectedDate) => {
  try {
    if (!selectedDate || !event) return null;
    const eventSchedule = event.schedules.find(
      (schedule) =>
        selectedDate.startOf('day') <= schedule.endDate.endOf('day') &&
        selectedDate.startOf('day') >= schedule.startDate.startOf('day') &&
        Boolean(getScheduleWeekDay(schedule, selectedDate))
    );

    if (!eventSchedule) return null;

    const weekDay = getScheduleWeekDay(eventSchedule, selectedDate);

    if (!weekDay || weekDay.isClosed) return null;

    return {
      title: event.title,
      location: event.venueName || event.locationDescription,
      startTime: DateTime.fromISO(selectedDate.toISODate())
        .setZone('America/New_York')
        .set({
          hour: getTimeValue(weekDay.openHour),
          minute: getTimeValue(weekDay.openMinute),
        })
        .toISO(),
      endTime: DateTime.fromISO(selectedDate.toISODate())
        .setZone('America/New_York')
        .set({
          hour: getTimeValue(weekDay.closeHour),
          minute: getTimeValue(weekDay.closeMinute),
        })
        .toISO(),
    };
  } catch (e) {
    console.error('Could not get event schedule', e.message);
    return null;
  }
};

export {
  getSchedule,
  isPast,
  createRoute,
  normalize,
  getCalendarParsedEvents,
  filterDateEvents,
  getAddToCalendarEvent,
};
