import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Loader } from 'semantic-ui-react';
import { track } from 'utils/analytics';
import { useClassName } from 'common/hooks';
import { getContentfulField, ContentfulRichText } from 'common/components';
import {
  HorizontalDatePicker,
  PriceTypeIndicator,
  Message,
} from 'public/components';
import { isDateChangeFee, isWristbandProduct } from 'public/helpers';
import { parseVenueMessages } from '../../helpers';

import './dates.less';

const NO_TICKETS_MESSAGE_TYPE = 'no-tickets';

const Dates = ({
  venue,
  selectedDate,
  onDateSelected,
  initialDate,
  stores,
  inventory,
}) => {
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const className = useClassName('Dates');

  useEffect(() => {
    if (!venue) return;
    const unavailableDates = venue?.priceTypes?.reduce(
      (acc, { date, type }) => (type === 'closed' ? acc.concat(date) : acc),
      []
    );
    setUnavailableDates(unavailableDates);
    if (selectedDate && !isDateUnavailable(selectedDate)) {
      setLoading(true);
      fetchSessions(selectedDate);
    }
  }, [venue]);

  useEffect(() => {
    if (
      (selectedDate && isDateUnavailable(selectedDate)) ||
      (inventory?.length && isUnavailable(inventory))
    ) {
      track('No tickets available', selectedDate);
    }
  }, [selectedDate, inventory]);

  const isUnavailable = (inventory) => {
    if (!inventory?.length) return true;

    return inventory?.every(
      (item) =>
        typeof item.quantity === 'object' && !Object.keys(item.quantity).length
    );
  };

  const fetchSessions = async (date) => {
    await fetchInventory(date);

    const inventoryForDate = stores.ticketInventory
      .get(venue.id, date)
      ?.filter((product) => !product.addon)
      ?.filter(
        (product) =>
          !isDateChangeFee(product.name) && !isWristbandProduct(product.name)
      );

    const available = !isUnavailable(inventoryForDate);
    onDateSelected(date, available);
    setLoading(false);
    if (!available) {
      throw new Error('Unavailable date');
    }
  };

  const fetchInventory = async (reservationDate) => {
    await stores.ticketInventory.search({
      venueId: venue.id,
      date: reservationDate,
      slug: venue.slug,
      forceRefresh: true,
    });

    if (venue.externalBookings && reservationDate)
      await stores.externalbookings.fetchAvailabilityByVenue(
        venue.slug,
        reservationDate
      );
  };

  const isIrregularHoursClosed = (isoDate) => {
    const irregularExists = Boolean(
      venue?.openingHours?.irregular.find(
        (irregular) => irregular?.date === isoDate
      )
    );

    if (!irregularExists) return true;

    return venue?.openingHours?.irregular.some((irregular) => {
      if (!irregular.isClosed) return false;

      if (irregular.type === 'day') {
        return irregular.date === isoDate;
      }

      const date = DateTime.fromISO(isoDate);
      if (irregular.type === 'range') {
        const start = DateTime.fromISO(irregular.startDate);
        const end = DateTime.fromISO(irregular.endDate);
        return start <= date && end >= date;
      }

      if (irregular.type === 'holiday') {
        return (
          irregular.holidayMonth + 1 === date.month &&
          irregular.holidayDate === date.day
        );
      }
    });
  };

  const isRegularHoursClosed = (isoDate) => {
    return venue?.openingHours?.regular.some((regular) => {
      if (!regular.isClosed) return false;

      return (
        regular.weekday === DateTime.fromISO(isoDate).weekdayLong.toLowerCase()
      );
    });
  };

  const isPromoClosed = (isoDate) => {
    const date = DateTime.fromISO(isoDate);
    if (venue.startDate && date < DateTime.fromISO(venue.startDate))
      return true;

    return venue.endDate && date > DateTime.fromISO(venue.endDate);
  };

  const isDateUnavailable = (isoDate) => {
    return (
      (isRegularHoursClosed(isoDate) && isIrregularHoursClosed(isoDate)) ||
      unavailableDates?.includes(isoDate) ||
      isPromoClosed(isoDate)
    );
  };

  const onDateSelectedError = (isoDate) => {
    setUnavailableDates([...unavailableDates, isoDate]);
  };

  if (!venue) return null;

  const renderNoTicketsMessage = () => {
    const venueMessages = parseVenueMessages(
      getContentfulField(venue?.content?.messages)
    );

    const noTicketsMessage = venueMessages.filter(
      (message) =>
        message.type === NO_TICKETS_MESSAGE_TYPE &&
        message.dates.includes(selectedDate)
    );

    if (noTicketsMessage.length) {
      return (
        <Message
          type="warning"
          content={<ContentfulRichText field={noTicketsMessage[0].content} />}
        />
      );
    }

    return <Message type="warning" content="No Tickets available" />;
  };

  const getInitialDate = () => {
    if (initialDate && !isDateUnavailable(initialDate)) return initialDate;
    if (venue.startFromDate) return venue.startFromDate;
    if (venue.startDate && !isDateUnavailable(venue.startDate))
      return venue.startDate;
    return null;
  };

  if (loading && (venue.hiddenDatePicker || venue.startFromDate))
    return (
      <Loader
        active
        inline="centered"
        size="big"
        className={className('loader')}
      />
    );

  if (venue.hiddenDatePicker) return null;

  return (
    <div className={className('container')}>
      <HorizontalDatePicker
        onDateSelected={fetchSessions}
        startDate={getInitialDate()}
        selectedDate={selectedDate}
        isDateUnavailable={isDateUnavailable}
        priceTypes={venue.priceTypes}
        isTicketsCalendar={true}
        onDateSelectedError={onDateSelectedError}
        selectOnLoad={Boolean(initialDate)}
      />
      {selectedDate &&
        (isDateUnavailable(selectedDate) || isUnavailable(inventory)) &&
        renderNoTicketsMessage()}
      {Boolean(venue?.priceTypes?.length) && (
        <div className={className('price-indicator-container')}>
          <PriceTypeIndicator />
        </div>
      )}
    </div>
  );
};

Dates.propTypes = {
  venue: PropTypes.object,
  selectedDate: PropTypes.string,
  onDateSelected: PropTypes.func.isRequired,
  stores: PropTypes.object,
  inventory: PropTypes.array,
};

export default Dates;
