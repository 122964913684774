import React from 'react';
import { flatten } from 'lodash';
import { Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { API_URL } from 'utils/env/client';

import { captureError } from 'utils/sentry';
import { Component } from 'common/helpers';
import { getCookie, setCookie, deleteCookie } from 'utils/helpers/cookie';
import {
  getItemBulkDiscount,
  trackCheckoutStarted,
  trackCheckoutStepViewed,
  validateInventory,
  trackCheckoutStepCompleted,
  trackOrderCompleted,
} from 'common/helpers';
import { isValidPhone } from 'utils/helpers/validations';
import PhoneNumberInput from 'public/components/Auth/PhoneNumberInput';
import { FieldContainer } from 'public/components/FieldContainer';

import CartStep from '../../Cart/helpers/CartStep';
import { getProductsQuantities, keys } from '../../Cart/helpers/discounts';
import {
  getDiscountValues,
  isTwoForOneDiscount,
} from '../../Cart/helpers/twoForOneCodes';
import { NewCheckoutContainer } from '../Components/Container';
import { STEPS } from '../const';
import { getCartTotals } from '../helpers';
import { NewCheckoutSummary } from '../Components/Summary';
import { NewCheckoutTicketsHeader } from '../Tickets/Header';
import { NewCheckoutPaymentForm } from './PaymentForm';
import { NewCheckoutPaymentContainer } from './StripePaymentContainer';

const validatePhoneNumber = (phoneNumber) => {
  if (phoneNumber && isValidPhone(phoneNumber)) return '';

  return 'Please provide a valid Phone Number.';
};

const mapItems = (cartItems) => {
  return cartItems
    .map((cartItem) => {
      if (cartItem?.venue?.venueType === 'parking') {
        return Object.entries(cartItem.quantities)
          .map(([ticketOptionId, quantity]) => {
            return [...Array(quantity).keys()]
              .map(() => {
                return {
                  ...cartItem,
                  quantities: {
                    [ticketOptionId]: 1,
                  },
                };
              })
              .flat();
          })
          .flat();
      }

      return [cartItem];
    })
    .flat();
};

@CartStep
@observer
@inject(
  'appSession',
  'externalbookings',
  'checkout',
  'me',
  'venues',
  'payment',
  'orders',
  'betaFeatures'
)
export default class Payment extends Component {
  constructor(props) {
    super(props);

    const phoneNumber = this.props.me?.user?.phone || '';
    this.state = {
      loading: true,
      displayReview: true,
      order: null,
      error: null,
      paymentIntent: null,
      discountValue: null,
      discountUsageId: null,
      redirect: this.getInitialRedirect(),
      phone: {
        value: phoneNumber,
        touched: false,
        error: validatePhoneNumber(phoneNumber),
      },
      isParkEntryEnabled: false,
    };
  }

  async componentDidMount() {
    trackCheckoutStarted(this.props.cartItems);
    trackCheckoutStepViewed(2);

    if (this.props.appSession.isLoggedIn()) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (params?.order_id) {
        try {
          const { intent, order } = await this.props.orders.waitComplete(
            params.order_id
          );

          if (intent?.last_payment_error) {
            throw new Error(intent.last_payment_error.message);
          }

          this.completeCheckout({ intent, order });
          return;
        } catch (ex) {
          this.onError(ex);
        }
      }

      for (const ci of this.props.cartItems) {
        if (ci.venue?.externalBookings)
          this.props.externalbookings.fetchAvailabilityByVenue(
            ci.venue.slug,
            ci.reservationDate
          );
      }

      this.getDiscount().then(async () => {
        try {
          const response = await this.props.orders.create({
            items: flatten(
              mapItems(this.props.cartItems).map((cartItem) => {
                const {
                  reservationDate,
                  startTime,
                  promoCode,
                  inventory,
                  quantities,
                  venueId,
                  bundleSlug,
                } = cartItem;

                return cartItem.tickets.map((inventoryItem) => {
                  const {
                    ticketOptionId,
                    bookingItemId,
                    addon,
                    passportType,
                    passportValidDays,
                    externalTicket,
                    externalVenue,
                  } = inventoryItem;
                  const quantity = quantities[ticketOptionId];
                  const discount = getItemBulkDiscount(
                    inventoryItem,
                    inventory,
                    quantities
                  );
                  const discountRateId = discount && discount.rateExternalId;
                  return {
                    quantity,
                    promoCode,
                    ticketOptionId,
                    discountRateId,
                    reservationDate,
                    startTime,
                    bookingItemId,
                    venueId,
                    bundleSlug,
                    addon,
                    passportType,
                    passportValidDays,
                    externalTicket,
                    externalVenue,
                  };
                });
              })
            ),
            discountUsageId: this.state.discountValue
              ? this.state.discountUsageId
              : null,
            discountValue: this.state.discountValue || 0,
            twoStepsPayment: true,
          });

          this.setState({
            order: response.order,
            paymentIntent: response.intent,
            loading: false,
          });
        } catch (error) {
          this.onError(error);
        }
      });
    }

    const isParkEntryEnabled = await this.props.betaFeatures.fetch(
      'Park Entry Pass'
    );
    this.setState({
      isParkEntryEnabled,
    });
  }

  async getDiscount() {
    const discountValue = getCookie(keys.DISCOUNT_VALUE_KEY) || 0;
    const discountCode = getCookie(keys.DISCOUNT_CODE_KEY);
    const discountUsageFromCookie = getCookie(keys.DISCOUNT_USAGE_KEY);
    const { cartItems } = this.props;

    try {
      if (!discountValue && !discountCode && !discountUsageFromCookie) return;

      // if discount was applied previously
      const discountUsage = JSON.parse(discountUsageFromCookie);
      const { id: prevUsageId, valid, userLoggedIn } = discountUsage;

      // user already logged in when discount was applied
      if (userLoggedIn && valid) {
        this.setState({ discountValue, discountUsageId: prevUsageId });
        return;
      }

      // delete previous redemption and create a new one
      deleteCookie(keys.DISCOUNT_USAGE_KEY);
      localStorage.removeItem(keys.DISCOUNT_USAGE_KEY);

      if (!valid) {
        deleteCookie(keys.DISCOUNT_VALUE_KEY);
        return;
      }

      await this.props.discounts.delete(prevUsageId);

      const productQuantities = getProductsQuantities(cartItems);
      const newDiscountUsage = await this.props.discounts.redeem(
        discountCode,
        productQuantities
      );
      newDiscountUsage.userLoggedIn = true;

      // Save cookie in case user reloads checkout page
      // Remove productIds from discountUsage and store them in localStorage
      const { productIds = [], ...rest } = newDiscountUsage;
      const discountUsageProductIdsString = JSON.stringify(productIds);
      const discountUsageString = JSON.stringify(rest);

      // Store discount product ids in localStorage
      localStorage.setItem(
        keys.DISCOUNT_USAGE_KEY,
        discountUsageProductIdsString
      );
      // Store discount usage without productIds in cookie
      setCookie(keys.DISCOUNT_USAGE_KEY, discountUsageString, 15);

      if (!newDiscountUsage?.valid) {
        throw new Error(
          newDiscountUsage.error || newDiscountUsage.errorMessage
        );
      }

      //save new usage in cookie here
      const { id: discountUsageId } = newDiscountUsage;

      if (isTwoForOneDiscount(newDiscountUsage)) {
        const { discount } = getDiscountValues(
          this.props.cartItems,
          newDiscountUsage,
          newDiscountUsage.reportingCategoryName
        );
        this.setState({ discountValue: discount, discountUsageId });
      } else {
        this.setState({ discountValue, discountUsageId });
      }
    } catch (error) {
      this.onError(error);
    }
  }

  getInitialRedirect() {
    if (!this.props.appSession.isLoggedIn()) {
      return '/signup?return=/cart/checkout';
    }
  }

  getValidationQuantity(item) {
    const { startTime, quantities } = item;
    return Object.keys(item.quantities).reduce((reducer, productId) => {
      const quantity = quantities[productId];
      return {
        ...reducer,
        [productId]: startTime ? { startTime, quantity } : quantity,
      };
    }, {});
  }

  validate() {
    const { externalbookings } = this.props;
    const { error } = this.state;

    if (error) {
      return {
        canSubmit: false,
        errorMessage: error.message,
        errorData: error.data,
      };
    }

    for (const ci of this.props.cartItems) {
      const validation = validateInventory(
        ci.inventory,
        this.getValidationQuantity(ci),
        ci.venue,
        externalbookings.get(ci.venue.slug, ci.reservationDate),
        this.state.isParkEntryEnabled
      );
      if (!validation.canSubmit) {
        return validation;
      }
    }

    return {
      canSubmit: true,
    };
  }

  onError = (error) => {
    this.setState({
      error,
      loading: false,
    });

    try {
      captureError(error);
    } catch (e) {
      // IGNORE RETHROW
    }
  };

  completeCheckout(response) {
    try {
      const { venues } = this.props;
      this.props.checkout.finish({
        ...response,
        order: {
          ...response.order,
          tickets: [
            ...response.order.tickets.map((ticket) => ({
              ...ticket,
              ticketOption: {
                ...ticket.ticketOption,
                hiddenDate:
                  venues.get(ticket.ticketOption.venueId)?.hiddenDatePicker ||
                  false,
              },
            })),
          ],
        },
        images: Object.fromEntries(
          this.props.cartItems
            .map((cartItem) => cartItem.inventory)
            .flat()
            .map((inventoryItem) => [
              inventoryItem.bookingItemId,
              inventoryItem.image,
            ])
        ),
      });

      trackOrderCompleted(response.order, this.props.cartItems);
      trackCheckoutStepCompleted(3);
    } catch (ex) {
      // Ignore tracking errors
    }

    deleteCookie(keys.DISCOUNT_VALUE_KEY);
    deleteCookie(keys.DISCOUNT_CODE_KEY);
    deleteCookie(keys.DISCOUNT_USAGE_KEY);
    localStorage.removeItem(keys.DISCOUNT_USAGE_KEY);

    this.setState({
      loading: false,
      redirect: '/cart/completed',
    });

    this.props.cart.clear();
  }

  getError = (err) => {
    const error = new Error(err.message);
    const data = {
      ['Your card has been declined.']: (
        <span>
          Your transaction has been declined, please contact us at{' '}
          <a href="mailto:guestservices@americandream.com">
            guestservices@americandream.com
          </a>{' '}
          for further assistance with your booking.
        </span>
      ),
    }[err.message];

    return data ? Object.assign(error, { data }) : error;
  };

  onSubmit = async (hidePayment, stripe, elements) => {
    try {
      this.props.checkout.finish({
        ...this.props.checkout.checkout,
        tracking: {
          order: this.state.order,
          cartItems: this.props.cartItems,
        },
      });

      this.setState({ loading: true });

      this.props.me.updatePhone({
        userId: this.props.me.user.id,
        phone: this.state.phone.value,
      });

      if (!hidePayment) {
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${location.origin}${location.pathname}?order_id=${this.state.order.id}`,
          },
          redirect: 'if_required',
        });

        if (error) {
          throw this.getError(error);
        }
      }

      const noWebhooks =
        this.state.order.totalAmount === 0 || API_URL.includes('localhost');
      if (noWebhooks) {
        await this.props.orders.confirm({
          orderId: this.state.order.id,
          paymentIntentId: this.state?.paymentIntent?.id,
          saveCard: true,
          discountUsageId: this.state.discountUsageId,
        });
      }

      const response = await this.props.orders.waitComplete(
        this.state.order.id,
        1,
        5,
        noWebhooks ? 0 : 2000
      );
      this.completeCheckout(response);
    } catch (err) {
      this.onError(err);
    }
  };

  renderContent = (hidePayment) => {
    const { phone } = this.state;

    return (
      <>
        <div style={{ marginBottom: '32px' }}>
          <NewCheckoutTicketsHeader showLogo={false} title="Payment" />
          <FieldContainer>
            <label htmlFor="phone">Confirm or update your phone number</label>
            <PhoneNumberInput
              onChange={(value) =>
                this.setState(({ phone }) => ({
                  phone: { ...phone, value, error: validatePhoneNumber(value) },
                }))
              }
              onBlur={() => {
                this.setState(({ phone }) => ({
                  phone: {
                    ...phone,
                    touched: true,
                  },
                }));
              }}
              id="phone"
              name="phone"
              error={phone.touched && phone.error}
              initialValue={phone.value}
            />
            <span style={{ marginTop: '4px', fontSize: '12px' }}>
              * You will be contacted in the event of any unexpected changes to
              your booking.
            </span>
          </FieldContainer>
        </div>
        <div>
          <NewCheckoutPaymentForm
            onComplete={(complete) =>
              this.setState({ formCompleted: complete })
            }
            onReady={() => {}}
            hidePayment={hidePayment}
          />
        </div>
      </>
    );
  };

  renderSummary = (hidePayment, stripe, elements) => {
    const { errorMessage, errorTitle, errorData } = this.validate();
    const error = (errorMessage || errorTitle) && {
      errorMessage,
      errorTitle,
      errorData,
    };
    const { formCompleted, order, phone } = this.state;

    return (
      <NewCheckoutSummary
        error={error}
        canSubmit={formCompleted && order && !phone.error}
        onSubmit={() => this.onSubmit(hidePayment, stripe, elements)}
        step={STEPS.Checkout}
        totals={{
          ...getCartTotals({
            cartItems: this.props.cartItems,
            discountValue: this.state.discountValue,
            fee: this.state?.order?.feeAmount,
          }),
        }}
        loading={this.state.loading}
      />
    );
  };

  render() {
    const { redirect, order, paymentIntent, error, loading } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    const hidePayment =
      (order && !paymentIntent) || (!order && !paymentIntent && error);

    return (
      <>
        {loading && (
          <Dimmer active inverted page>
            <Loader />
          </Dimmer>
        )}
        {hidePayment ? (
          <NewCheckoutContainer
            attrs={this.getAttrs()}
            content={this.renderContent(hidePayment)}
            summary={this.renderSummary(hidePayment)}
          />
        ) : (
          <NewCheckoutPaymentContainer
            clientSecret={paymentIntent?.client_secret}>
            {({ stripe, elements }) => (
              <NewCheckoutContainer
                attrs={this.getAttrs()}
                content={this.renderContent(hidePayment)}
                summary={this.renderSummary(hidePayment, stripe, elements)}
              />
            )}
          </NewCheckoutPaymentContainer>
        )}
      </>
    );
  }
}
