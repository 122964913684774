import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  ContentfulRichText,
  getContentfulField,
  SVGIcon,
} from 'common/components';
import { Message } from 'public/components';
import { useClassName } from 'common/hooks';

import './other-information.less';

const NO_TICKETS_MESSAGE_TYPE = 'no-tickets';

const parseOtherInformation = (otherInformation) => {
  if (!otherInformation) return [];

  return otherInformation.map((info, index) => ({
    title: getContentfulField(info.fields.title),
    content: info.fields.content,
    visible: getContentfulField(info.fields.visible),
    index,
  }));
};

const OtherInformation = ({ contentfulMessages, contentfulInformation }) => {
  const [otherInformation, setOtherInformation] = useState([]);
  const classNames = useClassName('OtherInformation');

  useEffect(() => {
    setOtherInformation(parseOtherInformation(contentfulInformation));
  }, [contentfulMessages?.length, contentfulInformation?.length]);

  const renderMessages = () => {
    if (!contentfulMessages?.length) return null;

    return contentfulMessages
      .filter((message) => message.type !== NO_TICKETS_MESSAGE_TYPE)
      .map((message, index) => (
        <Message
          key={index}
          type={message.type}
          content={<ContentfulRichText field={message.content} />}
        />
      ));
  };

  const toggleInfo = (info) => {
    setOtherInformation((otherInformation) =>
      otherInformation.map((oi) => {
        if (oi.index !== info.index) return oi;

        return { ...oi, visible: !oi.visible };
      })
    );
  };

  return (
    <div className={classNames('container')}>
      <div className={classNames('messages')}>{renderMessages()}</div>
      {Boolean(otherInformation.length) && (
        <>
          <h2>Other information</h2>
          {otherInformation.map((info, index) => (
            <div
              key={index}
              className={`${classNames('ticket-info')} ${
                info.visible && classNames('visible')
              }`}>
              <div
                onClick={() => toggleInfo(info)}
                className={classNames('ticket-info-header')}>
                <h4>{info.title}</h4>
                <SVGIcon name="angle-down" size="mini" />
              </div>
              {info.visible && (
                <ContentfulRichText
                  className={classNames('ticket-info-content')}
                  field={info.content}
                />
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

OtherInformation.propTypes = {
  venue: PropTypes.object,
};

export default OtherInformation;
