import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  LazyVisible,
  useClassName,
  usePreload,
  preloadMedia,
} from 'common/hooks';
import { ContentfulRichText } from 'common/components';

import HomepageButton from '../../Components/Button';
import HomepageImage from '../../Components/Image';
import trackHomepageSlide from '../../Components/trackHomepageSlide';
import trackHomepageSection from '../../Components/trackHomepageSection';
import useLoop from '../../Components/useLoop';
import useSlider from '../../Components/useSlider';
import SliderControls from '../../Components/SliderControls';
import Center from '../../Components/Center';
import SliderBullets from '../../Components/SliderBullets';

import 'swiper/swiper.less';
import './events.less';

export default function EventsSection({ section }) {
  const classNames = useClassName('EventsSection');
  const ref = useRef();
  const loop = useLoop({
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1128: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    },
    slidesLength: section.fields.events.length,
  });
  const [props, methods, state] = useSlider();
  usePreload((isDesktop) => {
    preloadMedia(section.fields.events, isDesktop);
  }, ref);

  return (
    <LazyVisible
      style={{
        '--primary-color': section.fields.primaryColor,
        '--secondary-color': section.fields.secondaryColor,
      }}
      className={classNames('container')}
      id={section.fields.sectionId}
      ref={ref}
      onLoad={() =>
        trackHomepageSection(() => ({
          id: section.fields.sectionId,
        }))
      }>
      <div className={classNames('header')}>
        <h3>{section.fields.header}</h3>
        <ContentfulRichText field={{ 'en-US': section.fields.subheader }} />
      </div>
      <div style={{ position: 'relative' }}>
        <div className={classNames('content')}>
          <Swiper
            height={542}
            {...loop}
            {...props}
            className={classNames('wrapper')}
            onSlideChange={({ realIndex }) =>
              trackHomepageSlide(() => ({
                activeIndex: realIndex,
                name: section.fields.events[realIndex].fields.name,
                section: section.fields.sectionId,
                url: section.fields.events[realIndex].fields.callToAction.fields
                  .url,
              }))
            }>
            {section.fields.events.map(({ fields, sys }, i) => {
              return (
                <SwiperSlide
                  key={sys.id}
                  className={classNames('slide-container')}>
                  <div
                    className={classNames('slide')}
                    style={{ animationDelay: `${i * 150}ms` }}>
                    <HomepageButton
                      cta={{
                        ...fields.callToAction,
                        fields: {
                          ...fields.callToAction.fields,
                          isHidden: true,
                        },
                      }}>
                      <HomepageImage
                        field={fields.desktop}
                        mobile={fields.mobile}
                      />
                    </HomepageButton>
                    <HomepageButton
                      cta={fields.callToAction}
                      className={classNames('cta')}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        {loop.loop && (
          <>
            <SliderControls
              slidePrev={methods.slidePrev}
              slideNext={methods.slideNext}
            />
            <Center>
              <SliderBullets
                activeIndex={state.activeIndex}
                length={section.fields.events.length}
                slideTo={methods.slideTo}
                style={{ marginTop: '24px' }}
              />
            </Center>
          </>
        )}
      </div>
    </LazyVisible>
  );
}
