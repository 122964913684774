import React from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import { startCase, toLower } from 'lodash';
import { inject, observer } from 'mobx-react';
import { DateTime } from 'luxon';
import { Loader, Button, Dimmer, Dropdown } from 'semantic-ui-react';
import { formatApiDate, parseApiDate } from 'utils/api';
import { formatCurrency } from 'utils/l10n';
import { Component } from 'common/helpers';
import { SVGIcon as Icon } from 'common/components';
import {
  isSoldOut,
  getQuantity,
  getItemName,
  getItemPrice,
} from 'common/helpers';
import { CustomMessage, AttractionLogo } from 'public/components';
import {
  getQuantities,
  getInventory,
  fetchInventory,
  getTicketsCount,
  validateTickets,
  getDateChangeFeeTicket,
  mapQuantitiesToTickets,
  isEquivalentTicket,
} from '../helpers/orderUpdate';
import { NewCheckoutQuantityControls } from '../../NewCheckout/Components/QuantityControls';
import ChangeContainer from './ChangeContainer';
import { isDateChangeFee, isWristbandProduct } from 'public/helpers';
import './ticket-selector.less';

@inject('ticketInventory', 'orderUpdate', 'venues')
@observer
export default class TicketSelector extends Component {
  constructor(props) {
    super(props);
    const { newTickets, currentTickets, venue } = this.props.orderUpdate;
    this.state = {
      currentQuantities: getQuantities(
        currentTickets.filter(({ ticketOption }) => !ticketOption.addon)
      ),
      reservationDate: parseApiDate(newTickets[0].ticketOption.date),
      startTime: newTickets[0].ticketOption.startTime,
      newTickets,
      quantities: getQuantities(newTickets),
      error: null,
      loading: true,
      expandedCalendar: false,
      changeDateFeeTicket: null,
      missingDateChangeFeeTicket: false,
      selectedVenue: venue,
      venuesList: [],
    };
  }

  async componentDidMount() {
    const { venues } = this.props;
    const [venuesList] = await venues.fetchItems({
      isListed: true,
      hasTickets: true,
      allowBookingSwap: true,
      venueType: 'attraction',
    });
    this.setState({
      venuesList,
    });
    this.fetchInventory();
  }

  async componentDidUpdate(lastProps, lastState) {
    const { orderUpdate } = this.props;
    const { handleNewTicketsChanged } = this.props;
    const {
      sessions,
      reservationDate,
      newTickets,
      changeDateFeeTicket,
      missingDateChangeFeeTicket,
      selectedVenue,
    } = this.state;

    if (
      lastState.reservationDate !== reservationDate ||
      lastState.selectedVenue.id !== selectedVenue.id
    ) {
      await this.fetchInventory();
      if (lastState.selectedVenue.id !== selectedVenue.id) {
        orderUpdate.setVenue(selectedVenue);
      }
      this.mapNewTickets();
    }

    if (lastState.sessions !== sessions && sessions?.length == 1) {
      this.handleChangeSession(sessions[0].startTime);
    }

    if (lastState.newTickets !== newTickets) {
      handleNewTicketsChanged(
        newTickets.filter(({ ticketOption }) => ticketOption.quantity > 0),
        changeDateFeeTicket,
        missingDateChangeFeeTicket,
        formatApiDate(reservationDate)
      );
    }
  }

  fetchInventory = async () => {
    try {
      const { ticketInventory } = this.props;
      const { reservationDate, selectedVenue } = this.state;

      const { sessions, error } = await fetchInventory(
        selectedVenue,
        reservationDate,
        ticketInventory
      );

      if (error) throw new Error(error);

      this.setState({
        hasSessions: Boolean(sessions?.length),
        sessions,
        loading: false,
      });
    } catch (err) {
      this.setState({
        error: err,
        loading: false,
      });
    }
  };

  getDateChangeFeeTicket = (tickets, reservationDate) => {
    const { ticketInventory, orderUpdate } = this.props;
    const { currentTickets } = orderUpdate;
    const { currentQuantities, selectedVenue } = this.state;
    const visitDate = currentTickets[0].ticketOption.date;
    const newDate = tickets[0].ticketOption.date;

    //If changing the date occurs within the next 24 hours after purchase, we do not charge a fee.
    if (this.purchasedWithin24Hours() || visitDate === newDate) return null;

    const changeDateFeeTicket = getDateChangeFeeTicket(
      reservationDate,
      ticketInventory,
      selectedVenue.id
    );

    if (!changeDateFeeTicket) {
      this.setState({
        missingDateChangeFeeTicket: true,
      });
      return null;
    }

    const totalCurrentTickets = getTicketsCount(currentQuantities);

    return {
      ticketOption: {
        ticketOptionId: changeDateFeeTicket.ticketOptionId,
        ticketOptionName: changeDateFeeTicket.name,
        startTime: '',
        bookingItemId: changeDateFeeTicket.bookingItemId,
        price: changeDateFeeTicket.price,
        tax: changeDateFeeTicket.tax,
        description: changeDateFeeTicket.description,
        date: formatApiDate(reservationDate),
        quantity: totalCurrentTickets,
        feeAmount: 0,
      },
    };
  };

  mapNewTickets = (startTime = null) => {
    const { newTickets, reservationDate, selectedVenue } = this.state;
    const { ticketInventory } = this.props;
    const inventory = getInventory(
      reservationDate,
      ticketInventory,
      selectedVenue.id
    )?.filter(
      (product) =>
        !product.addon &&
        !isDateChangeFee(product.name) &&
        !isWristbandProduct(product.name)
    );
    let selectedStartTime = startTime;

    const mappedTickets = [];

    for (const ticket of newTickets) {
      const equivalentTicket = inventory.find(
        (product) =>
          isEquivalentTicket(
            ticket.ticketOption.ticketOptionName,
            product.name
          ) &&
          (!selectedStartTime ||
            (typeof product.quantity === 'object' &&
              Object.keys(product.quantity).includes(selectedStartTime)))
      );

      if (!equivalentTicket) {
        this.setState({
          quantities: {},
          startTime: selectedStartTime,
          loading: false,
          newTickets: [],
        });
        return;
      }

      if (!selectedStartTime && typeof equivalentTicket.quantity === 'object') {
        selectedStartTime = Object.keys(equivalentTicket.quantity)[0];
      }

      mappedTickets.push({
        ticketOption: {
          ticketOptionId: equivalentTicket.ticketOptionId,
          ticketOptionName: equivalentTicket.name,
          startTime: selectedStartTime,
          bookingItemId: equivalentTicket.bookingItemId,
          price: equivalentTicket.price,
          tax: equivalentTicket.tax,
          description: equivalentTicket.description,
          date: formatApiDate(reservationDate),
          quantity: ticket.ticketOption.quantity,
          feeAmount: equivalentTicket.feeAmount,
        },
      });
    }

    const changeDateFeeTicket = this.getDateChangeFeeTicket(
      mappedTickets,
      reservationDate
    );

    this.setState({
      newTickets: mappedTickets,
      quantities: getQuantities(mappedTickets),
      startTime: selectedStartTime,
      loading: false,
      changeDateFeeTicket,
    });
  };

  setQuantity = (item, quantity, inventory) => {
    const { quantities, startTime, hasSessions, reservationDate } = this.state;
    const quantityToUse = hasSessions
      ? { startTime: startTime, quantity }
      : quantity;

    quantities[item.ticketOptionId] = quantityToUse;
    const newTickets = mapQuantitiesToTickets(
      inventory,
      reservationDate,
      quantities,
      startTime
    );

    this.setState({
      quantities,
      newTickets,
    });
  };

  handleChangeDate = (reservationDate) => {
    this.setState({
      reservationDate,
      startTime: null,
      quantities: {},
      missingDateChangeFeeTickets: false,
    });
  };

  handleChangeSession = (startTime) => {
    this.setState({
      startTime,
      quantities: {},
    });
    this.mapNewTickets(startTime);
  };

  handleVenueChange = (venueId) => {
    const { venuesList } = this.state;
    const newVenue = venuesList.find((v) => v.id === venueId);
    this.setState({
      selectedVenue: newVenue,
    });
  };

  purchasedWithin24Hours = () => {
    const { order } = this.props.orderUpdate;
    const now = new Date();
    const purchaseDate = parseApiDate(order.createdAt);

    return (now - purchaseDate) / (1000 * 60 * 60) < 24;
  };

  renderSoldOutLabel(item) {
    return (
      <CustomMessage type="error">
        {parseApiDate(item.date) < new Date()
          ? 'No Longer Available'
          : 'Sold Out'}
      </CustomMessage>
    );
  }

  renderError(errorMessage, errorTitle) {
    if (errorMessage) {
      return (
        <CustomMessage type="error" title={errorTitle}>
          <p>
            <strong>{errorTitle}</strong>
          </p>
          <p>{errorMessage}</p>
        </CustomMessage>
      );
    }
  }

  renderSessionOptions() {
    const { startTime, sessions } = this.state;
    return (
      <ChangeContainer title="Session time">
        <div className={this.getElementClass('sessions')}>
          {sessions.map((session) => {
            const remaining = session.capacityRemaining;
            return (
              <div key={session.startTime}>
                <Button
                  as="div"
                  type="button"
                  key={session.startTime}
                  size="small"
                  className={`session-slot ${
                    session.startTime === startTime ? 'selected' : ''
                  }`}
                  onClick={() => this.handleChangeSession(session.startTime)}
                  disabled={!remaining}>
                  <span>{session.name}</span>
                  <span className={this.getElementClass('remaining')}>
                    {!remaining && 'Sold out'}
                  </span>
                </Button>
              </div>
            );
          })}
        </div>
      </ChangeContainer>
    );
  }

  renderTickets(inventory) {
    const { quantities, currentQuantities, selectedVenue } = this.state;
    const totalCurrentTickets = getTicketsCount(currentQuantities);
    const totalTickets = getTicketsCount(quantities);
    let message = null;
    const messageType = 'warning';
    if (
      !inventory.length ||
      (this.state.hasSessions && !this.state.sessions.length)
    ) {
      message = <span>No tickets available</span>;
    } else if (this.state.hasSessions && !this.state.startTime) {
      message = <span>Select a time slot first</span>;
    } else if (totalTickets < totalCurrentTickets) {
      message = (
        <span>
          Add at least <strong>{totalCurrentTickets} tickets</strong>
        </span>
      );
    }

    return (
      <ChangeContainer title="Tickets">
        {message && <CustomMessage type={messageType}>{message}</CustomMessage>}
        <div className={this.getElementClass('tickets')}>
          {inventory.map((item) => {
            if (
              typeof item.quantity === 'object' &&
              !item.quantity[this.state.startTime]
            )
              return null;

            return (
              <div className="ticket" key={item.ticketOptionId}>
                <div className="row">
                  <AttractionLogo venue={selectedVenue} />
                  <div className="quantity">
                    {isSoldOut(item, this.state.startTime) ? (
                      this.renderSoldOutLabel(item)
                    ) : (
                      <NewCheckoutQuantityControls
                        onChange={(quantity) =>
                          this.setQuantity(item, quantity, inventory)
                        }
                        quantity={getQuantity(item, quantities) || 0}
                        min={item.minPurchase}
                        max={item.maxPurchase}
                      />
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="name-price">
                    <p className="name">
                      {getItemName(item, inventory, quantities)}
                    </p>
                    <p className="price">
                      {formatCurrency(
                        getItemPrice(item, inventory, quantities)
                      )}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </ChangeContainer>
    );
  }

  renderDatePicker() {
    const { reservationDate, expandedCalendar } = this.state;
    const today = new Date();
    return (
      <ChangeContainer title="Date of your visit">
        <div className={this.getElementClass('date-picker')}>
          <div
            className={`date ${expandedCalendar ? 'expanded' : ''}`}
            onClick={() =>
              this.setState({ expandedCalendar: !expandedCalendar })
            }>
            {DateTime.fromJSDate(reservationDate).toFormat('ccc, LLL dd')}
            <Icon name="angle-down" width={12} height={12} />
          </div>
          <div className={`calendar  ${expandedCalendar ? 'expanded' : ''}`}>
            <DayPicker
              className="day-picker"
              disabledDays={{ before: today }}
              selectedDays={reservationDate}
              month={reservationDate}
              onDayClick={this.handleChangeDate}
            />
          </div>
        </div>
        {!this.purchasedWithin24Hours() && (
          <CustomMessage type="warning">
            <span>
              Changing a date has a <strong>$5.00 fee / person</strong>. Ticket
              prices will vary depending on date and session selected.
            </span>
          </CustomMessage>
        )}
      </ChangeContainer>
    );
  }

  renderVenues() {
    const { venuesList, selectedVenue } = this.state;
    const selectOptions = venuesList.map((venue) => ({
      key: venue.id,
      value: venue.id,
      text: startCase(toLower(venue.name)),
    }));
    return (
      <ChangeContainer title="Venue">
        <Dropdown
          value={selectedVenue.id}
          options={selectOptions}
          onChange={(e, { value }) => this.handleVenueChange(value)}
          className={this.getElementClass('venues-selector')}
          icon={
            <Icon className="icon" name="angle-down" width={12} height={12} />
          }
        />
      </ChangeContainer>
    );
  }

  render() {
    const { ticketInventory, orderUpdate } = this.props;
    const { hasSessions, reservationDate, quantities, selectedVenue } =
      this.state;
    const { currentTickets, newAddons } = orderUpdate;

    const inventory = getInventory(
      reservationDate,
      ticketInventory,
      selectedVenue.id,
      currentTickets
    )?.filter(
      (product) =>
        !product.addon &&
        !isDateChangeFee(product.name) &&
        !isWristbandProduct(product.name)
    );

    if (!inventory || this.state.loading) {
      return (
        <Dimmer active inverted>
          <Loader size="large">Loading</Loader>
        </Dimmer>
      );
    }

    const { errorMessage, errorTitle } = validateTickets({
      reservationDate,
      venue: selectedVenue,
      quantities,
      ticketInventory,
      currentTickets,
    });

    return (
      <>
        {!selectedVenue.hiddenDatePicker && this.renderDatePicker()}
        {selectedVenue.allowBookingSwap &&
          !newAddons.length &&
          this.renderVenues()}
        {hasSessions ? this.renderSessionOptions() : null}
        {this.renderTickets(inventory)}
        {this.renderError(errorMessage, errorTitle)}
      </>
    );
  }
}

TicketSelector.propTypes = {
  venue: PropTypes.object.isRequired,
  handleNewTicketsChanged: PropTypes.func.isRequired,
};
