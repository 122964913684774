import React from 'react';
import { useClassName } from 'common/hooks';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SVGIcon } from 'common/components';

import './featured-deal.less';

const FeaturedDeal = ({ deal }) => {
  const className = useClassName('FeaturedDeal');

  return (
    <div
      className={className('card')}
      style={{
        backgroundImage: `url(${deal.image})`,
      }}>
      <div className={className('container')}>
        <div className={className('content')}>
          <span>{deal.venueName}</span>
          <Link to={`/deals/${deal.slug}`} className={className('control')}>
            <SVGIcon name="angle-right" size="mini" />
          </Link>
        </div>
      </div>
    </div>
  );
};

FeaturedDeal.propTypes = {
  deal: PropTypes.object.isRequired,
};

export default FeaturedDeal;
