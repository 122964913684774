import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { SVGIcon as Icon } from 'common/components';

import './view-type-options.less';

const OPTIONS = [
  {
    key: 'list',
    label: 'List',
  },
  {
    key: 'calendar',
    label: 'Calendar',
  },
];

const ViewTypeOptions = ({ viewType, handleViewTypeChange }) => {
  const className = useClassName('ViewTypeOptions');

  return (
    <div className={className('container')}>
      {OPTIONS.map((option) => (
        <div
          key={option.key}
          className={className([
            'option',
            `option-${option.key === viewType ? 'selected' : 'default'}`,
          ])}
          onClick={() => handleViewTypeChange(option.key)}>
          <Icon name={option.key} size="small" />
          <span>{option.label}</span>
        </div>
      ))}
    </div>
  );
};

ViewTypeOptions.propTypes = {
  viewType: PropTypes.string.isRequired,
  handleViewTypeChange: PropTypes.func.isRequired,
};

export default ViewTypeOptions;
