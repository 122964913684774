import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { Grid, Segment } from 'semantic-ui-react';
import { LoaderImage } from 'public/components/Loader';
import FeaturedDeal from './FeaturedDeal';
import { DesktopOnly } from 'common/components';

import './featured-deals.less';

import featuredSlideLeftAction from 'common/assets/directory-featured-slide-action-left.svg';
import featuredSlideRightAction from 'common/assets/directory-featured-slide-action-right.svg';

const FeaturedDeals = ({ featuredItems, slideRef, show }) => {
  const className = useClassName('FeaturedDeals');

  const renderFeatured = (deal) => (
    <Grid.Column key={deal.id} width={8}>
      <FeaturedDeal deal={deal} />
    </Grid.Column>
  );

  const nextFeatured = (scrollOffset) => {
    slideRef.current.scrollLeft += scrollOffset;
  };

  const shouldRenderFeaturedSection = featuredItems?.length > 0 && show;
  const renderControlActions = featuredItems.length > 1;

  if (!shouldRenderFeaturedSection) return null;

  return (
    <>
      <Segment className={className('featured-slide-wrapper')}>
        {renderControlActions && (
          <DesktopOnly className={className('control')}>
            <LoaderImage
              src={featuredSlideLeftAction}
              alt={'next'}
              loadingHeight={20}
              hideLoader
              fluid
              className={className('left-action')}
              onClick={() => nextFeatured(-800)}
            />
            <LoaderImage
              src={featuredSlideRightAction}
              alt={'previus'}
              loadingHeight={20}
              hideLoader
              fluid
              className={className('right-action')}
              onClick={() => nextFeatured(800)}
            />
          </DesktopOnly>
        )}
        <div className={className('sub-categories-wrapper')} ref={slideRef}>
          {featuredItems.map(renderFeatured)}
        </div>
      </Segment>
    </>
  );
};

FeaturedDeals.propTypes = {
  featuredItems: PropTypes.array.isRequired,
  show: PropTypes.bool,
  slideRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export default FeaturedDeals;
